import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class DetailHeader extends Component {

    render() {

        const createdDate = new Date(this.props.created);
        const updatedDate = new Date(this.props.updated);
        const zpad = (n, l) => {

            n = n.toString();
            while (l-- > n.length) {
                n = '0' + n;
            }

            return n;
        };

        const created = `${zpad(createdDate.getMonth()+1, 2)}/${zpad(createdDate.getDate(), 2)}/${createdDate.getFullYear()}`;
        const updated = `${zpad(updatedDate.getMonth()+1, 2)}/${zpad(updatedDate.getDate(), 2)}/${updatedDate.getFullYear()}`;

        return (
            <div className="row" style={{ marginTop: '2%', marginBottom: '2%' }}>
                <div className="col-12">
                    <div className="text-center row">
                        <div className="col-12">
                            <h2>{this.props.name}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            <div className="row">
                                <div className="col-12"><em>Created: <u>{created}</u></em></div>
                            </div>
                            <div className="row">
                                <div className="col-12"><em>Last Updated: <u>{updated}</u></em></div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <em>Created By: &nbsp;
                                        <u>
                                            <Link to={`/users/${this.props.creator._id}`} style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
                                                {this.props.creator.username}
                                            </Link>
                                        </u>
                                    </em>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailHeader;
