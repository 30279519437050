import React, { Component } from 'react';

class PermissionItem extends Component {

    constructor() {

        super();
        this.onRemove = this.onRemove.bind(this);
    }

    onRemove() {

        return this.props.onRemove(this.props.permission);
    }

    render() {

        return (
            <li className="list-group-item">
                <div className="row">
                    <div className="col-6">
                        {this.props.permission}
                    </div>
                    <div className="col-6 text-right">
                        <span style={{ cursor: 'pointer' }} onClick={this.onRemove}>
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </li>
        );
    }
}

class PermissionsPanel extends Component {

    constructor() {

        super();
        this.state = {
            newPermission: ''
        };
        this.onNewPermissionKeyed = this.onNewPermissionKeyed.bind(this);
        this.onNewPermissionPossiblySubmitted = this.onNewPermissionPossiblySubmitted.bind(this);
    }

    onNewPermissionKeyed(e) {

      this.setState({ newPermission: e.target.value });
  }

    onNewPermissionPossiblySubmitted(e) {

        if (e.key === 'Enter') {
            this.props.onAdd(this.state.newPermission.slice());
            this.setState({ newPermission: '' });
        }
    }

    render() {

        const title = this.props.title ?
                      (<span className="text-center"><strong>{this.props.title}</strong></span>) :
                      null;

        return (
            <div className="row">
                <div className="col-12">
                    {title}
                    <div className="row">
                        <div className="col-12">
                            <ul className="list-group">
                                {
                                    this.props.permissions && this.props.permissions.map((p) => {

                                        return <PermissionItem key={p} permission={p} onRemove={this.props.onRemove}/>
                                    })
                                }
                                <li className="list-group-item">
                                    <style dangerouslySetInnerHTML={{__html: `
                                        .no-lines:focus {
                                            outline: none;
                                            border-color: inherit;
                                            -webkit-box-shadow: none;
                                            box-shadow: none;
                                        }
                                    `}}>
                                    </style>
                                    <input type="text"
                                           value={this.state.newPermission}
                                           className="form-control no-lines"
                                           style={{ border: 'none' }}
                                           placeholder={this.props.placeholder}
                                           onChange={this.onNewPermissionKeyed}
                                           onKeyPress={this.onNewPermissionPossiblySubmitted}
                                           />
                                </li>
                            </ul>
                        </div>
                        <div className="col-6">
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PermissionsPanel;
