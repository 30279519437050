import React from 'react';
import AppTypeSelect from '../../../components/shared/app-type-select';

class AppTypeCapsule extends React.Component {

    render() {

        return (
            <div className="row" style={{ marginTop: '2%' }}>
                <div className="col-12">
                    <span className="text-left">
                        <strong>App Type</strong>
                    </span>
                    <AppTypeSelect
                        onChange={this.props.onChange}
                        selected={this.props.selected} />
                </div>
            </div>
        );
    }
}

export default AppTypeCapsule;
