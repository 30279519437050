import React, { Component } from 'react';
import {
    Redirect
} from 'react-router-dom';
import FormError from '../../../components/shared/form-error';
import RedirectsPanel from '../../../components/shared/redirects-panel';
import ProviderSelect from '../../../components/shared/provider-select';
import AppTypeSelect from '../../../components/shared/app-type-select';
import ImplicitCheckbox from '../../../components/shared/implicit-checkbox';
import PkceCheckbox from '../../../components/shared/pkce-checkbox';
import PasswordCheckbox from '../../../components/shared/password-checkbox';
import PermissionsPanel from '../../../components/shared/permissions-panel';
import Api from '../../../components/shared/api';
import OverrideEmailValidation from '../../../components/shared/override-email-validation';

class CreateForm extends Component {

    constructor() {

        super();
        this.state = {
            appType: null,
            error: null,
            implicit: false,
            moreInformation: '',
            name: '',
            redirect: null,
            redirectUrls: [],
            permissions: [],
            privacyPolicy: '',
            providers: [],
            provider: '',
            pkce: false,
            overrideEmailValidation: false,
            password: false,
            termsAndConditions: '',
            title: '',
            appIconUrl: ''
        };

        this.onDomainAdd = this.onDomainAdd.bind(this);
        this.onDomainRemove = this.onDomainRemove.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.onProviderSelectChange = this.onProviderSelectChange.bind(this);
        this.onAppTypeSelectChange = this.onAppTypeSelectChange.bind(this);
        this.onImplicitChanged = this.onImplicitChanged.bind(this);
        this.onPkceChanged = this.onPkceChanged.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onOverrideEmailValidationChanged = this.onOverrideEmailValidationChanged.bind(this);
        this.onTextInputChangeGen = this.onTextInputChangeGen.bind(this);
        this.onPermissionAdd = this.onPermissionAdd.bind(this);
        this.onPermissionRemove = this.onPermissionRemove.bind(this);
        this.api = new Api();
    }

    componentDidMount() {

        this.api.exec(`/providers`)
        .then((providers) => {
            this.setState({ providers });
        })
        .catch((err) => {

            this.setState({ error: err.toString() });
        })
    }

    onTextInputChangeGen(fieldName) {

        return (e) => {

            return this.setState({ [fieldName]: e.target.value });
        };
    }

    onProviderSelectChange(name) {

        this.setState({
            provider: (name && name.length > 0) ? name : ''
        });
    }

    onAppTypeSelectChange(name) {

        this.setState({
            appType: name
        });
    }

    onDomainAdd(added) {

        const redirectUrls = this.state.redirectUrls.slice();
        if (redirectUrls.findIndex((r) => { return r === added; }) >= 0) {
            return this.setState({ error: `Domain "${added}" already added.` });
        }
        else {
            redirectUrls.push(added);
            return this.setState({ redirectUrls });
        }
    }

    onDomainRemove(removed) {

        const redirectUrls = this.state.redirectUrls.slice();
        const index = redirectUrls.findIndex((r) => { return r === removed; });

        if (index >= 0) {
            redirectUrls.splice(index, 1);
            return this.setState({ redirectUrls });
        }
    }

    onImplicitChanged(implicit) {

        return this.setState({
            implicit
        });
    }

    onPkceChanged(pkce) {

        return this.setState({
            pkce
        });
    }

    onPasswordChanged(password) {

        return this.setState({
            password
        });
    }

    onOverrideEmailValidationChanged(overrideEmailValidation) {

        return this.setState({
            overrideEmailValidation
        });
    }

    onPermissionAdd(permission) {

        const permissions = this.state.permissions.slice();
        if (permissions.findIndex((p) => { return p === permission; }) >= 0) {
            return this.setState({ error: `Permission "${permission}" already added.` });
        }
        else {
            permissions.push(permission);
            return this.setState({ permissions });
        }
    }

    onPermissionRemove(permission) {

        const permissions = this.state.permissions.slice();
        const index = permissions.findIndex((p) => { return p === permission; });
        if (index >= 0) {
            permissions.splice(index, 1);
            return this.setState({ permissions });
        }
    }

    onSubmitClick() {

        const {
            name,
            redirectUrls
        } = this.state;

        if (name === '') {
            return this.setState({ error: 'You must provide a name.' });
        }

        for (let i = 0; i < redirectUrls.length; ++i) {
            if (process.env.REACT_APP_APP_ENV === 'production') {
                if (redirectUrls[i].indexOf('https://') !== 0) {
                    return this.setState({ error: `Redirect URL "${redirectUrls[i]}" invalid - all redirects must begin with "https://".`});
                }
            }
        }

        const payload = {
            name: this.state.name,
            implicit: this.state.implicit,
            authenticationProvider: this.state.provider.length > 0 ? this.state.provider : null,
            redirectUrls: this.state.redirectUrls,
            permissions: this.state.permissions,
            applicationType: this.state.appType,
            pkceEnabled: this.state.pkce,
            passwordEnabled: this.state.password,
            overrideEmailValidation: this.state.overrideEmailValidation,
            displayable: {
                privacyPolicyUrl: this.state.privacyPolicy || null,
                termsAndConditionsUrl: this.state.termsAndConditions || null,
                content: {
                    title: this.state.title || null,
                    moreInfoUrl: this.state.moreInformation,
                    appIconUrl: this.state.appIconUrl || null
                }
            }
        };

        this.api.exec(`/user/organizations/${this.props.organizationId}/applications`, 'POST', payload)
        .then((result) => {

            if (result.error) {
                return this.setState({ error: result.message });
            }

            return this.setState({
                redirect: `/organizations/${this.props.organizationId}/applications/${result._id}`
            });
        })
        .catch((err) => {

            this.setState({ error: err.toString() });
        });
    }

    render() {

        const { redirect, error, redirectUrls, permissions } = this.state;
        let redirectPlaceholder = '';

        if (redirect) {
            return (<Redirect to={redirect} />);
        }

        if (redirectUrls.length === 0) {
            redirectPlaceholder = 'Add a redirect to this application.';
        }
        else {
            redirectPlaceholder = 'Add more redirects to this application.';
        }

        let permissionPlaceholder = '';
        if (permissions.length === 0) {
            permissionPlaceholder = 'Add a permission to this application.';
        }
        else {
            permissionPlaceholder = 'Add more permissions to this application.';
        }

        return (
            <div className="row" style={{ marginTop: '2%' }}>
                <div className="col-12">
                    <FormError width="10" offset="1" message={error} />
                    <div className="row">
                        <div className="col-6">
                            <div className="row">
                                <div className="col-12">
                                    <div style={{ fontWeight: 'bold' }}>Application Name</div>
                                    <input type="text" className="form-control" placeholder="Application Name" onChange={this.onTextInputChangeGen('name')} />
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '2%' }}>
                                <div className="col-12">
                                    <div>
                                        <div style={{ fontWeight: 'bold' }}>Authentication Provider (optional)</div>
                                        <ProviderSelect providers={this.state.providers} onProviderChange={this.onProviderSelectChange} />
                                    </div>
                                    <div>
                                        <div style={{ fontWeight: 'bold' }}>Application Type</div>
                                        <AppTypeSelect selected={this.state.appType} onChange={this.onAppTypeSelectChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 pull-right">
                                    <div className="row">
                                        <div className="col-6">
                                            <ImplicitCheckbox implicit={this.state.implicit} onChange={this.onImplicitChanged} />
                                        </div>
                                        <div className="col-6 text-right">
                                            <PkceCheckbox checked={this.state.pkce} onChange={this.onPkceChanged} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 pull-right">
                                    <div className="row">
                                        <div className="col-6">
                                            <PasswordCheckbox checked={this.state.password} onChange={this.onPasswordChanged} />
                                        </div>
                                        <div className="col-6 text-right">
                                            <OverrideEmailValidation checked={this.state.overrideEmailValidation} onChange={this.onOverrideEmailValidationChanged} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary" onClick={this.onSubmitClick}>Submit</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-12">
                                    <div style={{ fontWeight: 'bold' }}>Redirects</div>
                                    <RedirectsPanel placeholder={redirectPlaceholder}
                                                    urls={this.state.redirectUrls}
                                                    onAdd={this.onDomainAdd}
                                                    onRemove={this.onDomainRemove} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div style={{ fontWeight: 'bold' }}>Permissions</div>
                                    <PermissionsPanel placeholder={permissionPlaceholder}
                                                    permissions={this.state.permissions}
                                                    onAdd={this.onPermissionAdd}
                                                    onRemove={this.onPermissionRemove} />
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '1%' }}>
                                <div className="col-12">
                                    <div style={{ fontWeight: 'bold' }}>Links (optional)</div>
                                    <div style={{ paddingLeft: '2%' }}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div><em>Terms and Conditions</em></div>
                                                <input type="text" className="form-control" onChange={this.onTextInputChangeGen('termsAndConditions')}></input>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div><em>Privacy Policy</em></div>
                                                <input type="text" className="form-control" onChange={this.onTextInputChangeGen('privacyPolicy')}></input>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div><em>More Information Link</em></div>
                                                <input type="text" className="form-control" onChange={this.onTextInputChangeGen('moreInformation')}></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '1%' }}>
                                <div className="col-12">
                                    <div style={{ fontWeight: 'bold' }}>Portal Content</div>
                                    <div style={{ paddingLeft: '2%' }}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div><em>Portal Title (optional)</em></div>
                                                <input type="text" className="form-control" onChange={this.onTextInputChangeGen('title')}></input>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div><em>App Icon URL (optional)</em></div>
                                                <input type="text" className="form-control" onChange={this.onTextInputChangeGen('appIconUrl')}></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateForm;
