import React, { Component } from 'react';
import AppContainer from '../../components/shared/app-container';
import { ApplicationCreateApp } from './components/application-create-app';

class ApplicationCreate extends Component {

    constructor({ match }) {

        super();
        this.state = {
            organizationId: match.params.organizationId
        };
    }

    render() {
        return (
            <AppContainer application={<ApplicationCreateApp organizationId={this.state.organizationId} />} />
        );
    }
}

export default ApplicationCreate;
