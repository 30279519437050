class Api {

    constructor() {

        const hostname = window && window.location && window.location.hostname;

        if (hostname === 'stage-manage.golfid.io') {
            this.uri = 'https://stage-api.golfid.io/api/v1/sso/admin';
        }
        else if (hostname === 'stage-rc3-manage.golfid.io') {
            this.uri = 'https://stage-rc3-api.golfid.io/api/v1/sso/admin';
        }
        else if (hostname === 'beta-manage.golfid.io') {
            this.uri = 'https://beta-api.golfid.io/api/v1/sso/admin';
        }
        else if (hostname === 'manage.golfid.io' || hostname === 'manage-origin.golfid.io') {
            this.uri = 'https://api.golfid.io/api/v1/sso/admin';
        }
        else {
            this.uri = `http://localhost:18934/api/v1/sso/admin`;
        }
    }

    exec(resource, method, payload) {

        method = method || 'GET';
        return new Promise((resolve, reject) => {

            let token;
            const base = localStorage.getItem('gnid.admin');

            if (base) {
                token = JSON.parse(base).token;
            }

            const request = {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            };

            if (payload) {
                request.body = typeof payload !== 'string' ? JSON.stringify(payload) : payload;
            }

            fetch(`${this.uri}${resource}`, request)
            .then(async (response) => {

                if (response.status === 401) {
                    localStorage.removeItem('gnid.admin');
                    console.log('Unauthorized!');
                    window.location.href = '/login?r=401';
                    return;
                }

                try {
                    return resolve(await response.json());
                }
                catch (e) {
                    return resolve();
                }

            })
            .catch((err) => {

                if (err.status === 401) {
                    console.error('Unauthorized!');
                    localStorage.removeItem('gnid.admin');
                    window.location.href = '/login?r=401';
                }
                return reject(err);
            });
        });
    }
}

export default Api;
