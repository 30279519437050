import React, { Component } from 'react';
import SummaryHeader from './summary-header';

class ListedUser extends Component {

    constructor() {

        super();
        this.onClick = this.onClick.bind(this);
    }

    onClick() {

        this.props.onListChange(this.props.id);
    }

    render() {

        const arrowClasses = `col-4 ${this.props.inverted ? 'text-left' : 'text-right' }`
        const nameClasses = `col-8${!this.props.immutable ? (this.props.inverted ? ' text-right' : ' text-left') : ''}`
        const listItemClasses = `list-group-item${this.props.immutable ? ' disabled' : ''}`;
        const shiftButtonClasses = `fas fa-arrow-${this.props.direction}`;
        const shiftButton = !this.props.immutable ?
                            (
                                <div className={arrowClasses} style={{ cursor: 'pointer' }} onClick={this.onClick}>
                                    <i className={shiftButtonClasses}></i>
                                </div>
                            ) : null;

        return (
            !this.props.inverted ?
                <li className={listItemClasses}>
                    <div className="row">
                        <div className={nameClasses}>
                            {this.props.name}
                        </div>
                        {shiftButton}
                    </div>
                </li>
            :
            <li className={listItemClasses}>
                <div className="row">
                    {shiftButton}
                    <div className={nameClasses}>
                        {this.props.name}
                    </div>
                </div>
            </li>
        )
    }
}

class UserList extends Component {

    render() {

        return (
            <ul className="list-group">
                <SummaryHeader name={this.props.listName} />
                {this.props.users.length > 0 ?
                    this.props.users.map((u) => {
                        return <ListedUser id={u.id}
                                           key={u.id}
                                           name={u.name}
                                           immutable={u.immutable}
                                           inverted={this.props.inverted}
                                           direction={this.props.direction}
                                           onListChange={this.props.onListChange} />;
                    }) :
                    <li className="list-group-item text-center"><em>No more users.</em></li>
                }
            </ul>
        );
    }
}

class UserSwitchList extends Component {

    render() {

        return (
            <div className="row" style={this.props.style}>
                <div className="col-6">
                    <UserList listName={this.props.outTitle || 'Available'} direction={this.props.outDirection || 'right'} users={this.props.usersOut} onListChange={this.props.onUserOutChange} />
                </div>
                <div className="col-6">
                    <UserList listName={this.props.inTitle || 'To Be Added'} direction={this.props.inDirection || 'left'} inverted users={this.props.usersIn} onListChange={this.props.onUserInChange} />
                </div>
            </div>
        )
    }
}

export default UserSwitchList;
