import React, { Component } from 'react';
import Api from '../../../components/shared/api';
import OrganizationList from '../../../components/shared/organization-list';
import UserStatusCard from './user-status-card';
import UserManagementPanel from './user-management-panel';

class UserDetailApp extends Component {

    constructor() {

        super();
        this.state = {
            mounted: false,
            user: null
        };
        this.api = new Api();
        this.onUserAltered = this.onUserAltered.bind(this);
    }

    componentDidMount() {

        this.api.exec(`/users/${this.props.id}`)
        .then((user) => {

            this.onUserAltered(user);
        })
        .catch((err) => {

            console.error(err);
            alert(`An error occurred. Please refresh to try again.`);
        });
    }

    onUserAltered(user) {

        this.setState({ mounted: true, user });
    }

    render() {

        if (!this.state.mounted) {
            return null;
        }

        const {
            _id,
            username,
            createdAt,
            updatedAt,
            organizations,
            status
        } = this.state.user;

        return (
            <div className="row" style={{ marginTop: '3%' }}>
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2>{username}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <UserStatusCard active={status.active}
                                            createdAt={createdAt}
                                            updatedAt={updatedAt}
                                            passwordUpdateAt={status.passwordUpdateAt} />
                        </div>
                        <div className="col-4">
                            <OrganizationList organizations={organizations} />
                        </div>
                        <div className="col-4">
                            <UserManagementPanel id={_id} onUserAltered={this.onUserAltered}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {
    UserDetailApp
};
