import React from 'react';
import AppTypeSelect from '../../../components/shared/app-type-select';

class AppChannelIdCapsule extends React.Component {

  constructor(props) {

    super();
    this.state = {
      channelId: props.channelId ? props.channelId : '',
    };

    this.fieldUpdateMethods = {
      channelId: props.onChange,
    };

    this.onKeyPressedGen = this.onKeyPressedGen.bind(this);
    this.onClickGen = this.onClickGen.bind(this);
  }

  onKeyPressedGen(fieldName) {
    return (e) => {
      return this.setState({ [fieldName]: e.target.value });
    };
  }

  onClickGen(fieldName) {
    return (e) => {
      return this.fieldUpdateMethods[fieldName](this.state[fieldName]);
    };
  }

  render() {

    return (
      <div className="row" style={{ marginTop: '2%' }}>
        <div className="col-12">
          <span className="text-left">
            <strong>Channel ID</strong>
          </span>
          <div className="row">
            <div className="col-10">
              <input type="text" className="form-control" value={this.state.channelId} onChange={this.onKeyPressedGen('channelId') } />
            </div>
            <div className="col-2">
                <button className="btn btn-small btn-default" onClick={this.onClickGen('channelId')}><i className="fas fa-check"></i></button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppChannelIdCapsule;
