import React from 'react';
import ImplicitCore from './implicit-core';

class ImplicitCheckbox extends ImplicitCore {

    constructor(props) {

        super(props);
        this.onChecked = this.onChecked.bind(this);
    }

    onChecked(e) {

        super.onImplicitChange(e.target.checked);
    }

    render() {

        const component = (
            <div className="form-check">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input" checked={this.state.implicit} onChange={this.onChecked} />
                Implicit
              </label>
            </div>

        );

        return super.render(component);
    }
}

export default ImplicitCheckbox;
