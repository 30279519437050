import React, { Component } from 'react';
import StatusCard from '../../../components/shared/status-card';

const whenExpires = (expirationDate) => {

    const left = (new Date(expirationDate).getTime() - new Date().getTime());
    const times = {
        thirtyDays: 1000 * 60 * 60 * 24 * 30,
        fiveDays: 1000 * 60 * 60 * 24 * 5,
    };

    if (left > times.thirtyDays) {
        return 0;
    }
    else if (left <= times.thirtyDays && left > times.fiveDays) {
        return 1;
    }
    else if (left <= times.fiveDays && left > 0) {
        return 2;
    }
    else {
        return 3;
    }
};

class UserStatusCard extends Component {

    render() {

        console.log(this.props.passwordUpdateAt);
        const statusCardData = [{
            title: 'Active',
            data: this.props.active ? 'Yes' : 'No',
            classes: this.props.active ?
                     (whenExpires(this.props.passwordUpdateAt) !== 3 ? 'list-group-item-success' : 'list-group-item-warning')
                     : 'list-group-item-danger',
            hover: this.props.active ?
                   (whenExpires(this.props.passwordUpdateAt) === 3 ? 'This account is soft-disabled because its password is expired.' : null)
                   : null
        }, {
            title: 'Created',
            data: this.props.createdAt,
            type: 'date'
        }, {
            title: 'Updated',
            data: this.props.updatedAt,
            type: 'date'
        }, {
            title: 'Password Expires',
            data: this.props.passwordUpdateAt,
            type: 'date',
            classes: (function (pua) {

                switch (whenExpires(pua)) {
                    case 0: return null;
                    case 1: return 'list-group-item-warning';
                    case 2:
                    case 3:
                    default:
                        return 'list-group-item-danger';
                }
            })(this.props.passwordUpdateAt),
            hover: (function (pua) {

                switch (whenExpires(pua)) {
                    case 0: return null;
                    case 1: return `This account's password will expire in less than 30 days.`;
                    case 2: return `This account's password will expire in less than 5 days.`;
                    case 3:
                    default:
                        return `This account's password has expired.`;
                }
            })(this.props.passwordUpdateAt)
        }];

        return (
            <div className="container container-fluid">
                <StatusCard header="Status" data={statusCardData} />
            </div>
        );
    }
}

export default UserStatusCard;
