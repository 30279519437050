import React, { Component } from 'react';
import {
    Redirect
} from 'react-router-dom';
import Api from '../../../components/shared/api';
import FormError from '../../../components/shared/form-error';
import AppStatusPanel from './app-status-panel';
import AppManagePanel from './app-manage-panel';

class ApplicationDetailApp extends Component {

    constructor() {

        super();
        this.state = {
            application: null,
            providers: [],
            redirect: null,
            error: null,
            success: null
        };

        this.updateApplication = this.updateApplication.bind(this);
        this.onDelegateChanged = this.onDelegateChanged.bind(this);
        this.onDelegateWhitelistChanged = this.onDelegateWhitelistChanged.bind(this);
        this.onDelegateWhitelistAdded = this.onDelegateWhitelistAdded.bind(this);
        this.onDelegateWhitelistRemoved = this.onDelegateWhitelistRemoved.bind(this);
        this.onDomainAdded = this.onDomainAdded.bind(this);
        this.onDomainRemoved = this.onDomainRemoved.bind(this);
        this.onChannelIdChanged = this.onChannelIdChanged.bind(this);
        this.onClientSecretReset = this.onClientSecretReset.bind(this);
        this.onDeleteApplication = this.onDeleteApplication.bind(this);
        this.onToggleApplication = this.onToggleApplication.bind(this);
        this.onProviderChanged = this.onProviderChanged.bind(this);
        this.onImplicitChange = this.onImplicitChange.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailed = this.onFailed.bind(this);
        this.onAppTypeChanged = this.onAppTypeChanged.bind(this);
        this.onPkceChanged = this.onPkceChanged.bind(this);
        this.onOverrideEmailValidationChanged = this.onOverrideEmailValidationChanged.bind(this);
        this.onPasswordChanged = this.onPasswordChanged.bind(this);
        this.onTermsAndConditionsUpdated = this.onTermsAndConditionsUpdated.bind(this);
        this.onPrivacyPolicyUpdated = this.onPrivacyPolicyUpdated.bind(this);
        this.onTitleUpdated = this.onTitleUpdated.bind(this);
        this.onAppIconUpdated = this.onAppIconUpdated.bind(this);
        this.onMoreInfoUpdated = this.onMoreInfoUpdated.bind(this);
        this.onPermissionAdded = this.onPermissionAdded.bind(this);
        this.onPermissionRemoved = this.onPermissionRemoved.bind(this);
        this.api = new Api();
    }

    updateApplication(payload, successMessage) {

        this.api.exec(`/user/organizations/${this.props.organizationId}/applications/${this.props.applicationId}`, 'PUT', payload)
        .then((application) => {

            if (application) {
                if (application.error) {
                    return this.onFailed(application.message);
                }

                if (Object.keys(application).length) {
                    this.setState({ application, success: successMessage || null, error: null });
                }
            }
        })
        .catch((err) => {

            this.onFailed(err.toString());
        });
    }

    onDomainAdded(domain) {

        if (process.env.REACT_APP_APP_ENV === 'production') {
            if (domain.indexOf('https://') !== 0) {
                return this.onFailed(`Invalid redirect URL "${domain}": redirects must begin with "https://".`);
            }
        }

        const redirectUrls = this.state.application.redirectUrls.slice();
        redirectUrls.push(domain);

        return this.updateApplication({ redirectUrls });
    }

    onDomainRemoved(domain) {

        const redirectUrls = this.state.application.redirectUrls.slice();
        const index = redirectUrls.findIndex((ru) => { return ru === domain; });
        if (index >= 0) {
            redirectUrls.splice(index, 1);
            return this.updateApplication({ redirectUrls });
        }
    }

    onChannelIdChanged(channelId) {

        return this.updateApplication({ channelId: channelId });
    }

    onClientSecretReset() {

        return this.updateApplication({ clientSecret: true }, `Secret updated. Click the eye to reveal the new secret.`);
    }

    onDelegateChanged(delegateEnabled) {

        return this.updateApplication({ delegateEnabled });
    }

    onDelegateWhitelistChanged(delegateWhitelistEnabled) {

        return this.updateApplication({ delegateWhitelistEnabled });
    }

    onDelegateWhitelistAdded(ip) {
        const delegateWhitelist = this.state.application.flowManagement.delegate.whitelist.slice();
        delegateWhitelist.push(ip);
        return this.updateApplication({ delegateWhitelist });
    }

    onDelegateWhitelistRemoved(ip) {

        const delegateWhitelist = this.state.application.flowManagement.delegate.whitelist.slice();
        const index = delegateWhitelist.findIndex((ru) => { return ru === ip; });
        if (index >= 0) {
            delegateWhitelist.splice(index, 1);
            return this.updateApplication({ delegateWhitelist });
        }
    }

    onProviderChanged(authenticationProvider) {

        return this.updateApplication({ authenticationProvider });
    }

    onAppTypeChanged(applicationType) {

        return this.updateApplication({ applicationType });
    }

    onDeleteApplication() {

        this.api.exec(`/user/organizations/${this.props.organizationId}/applications/${this.props.applicationId}`, 'DELETE')
        .then(() => {

            this.setState({ redirect: `/organizations/${this.props.organizationId}` });
        })
        .catch((err) => {

            this.onFailed(err.toString());
        });
    }

    onToggleApplication() {

        return this.updateApplication({
            active: !this.state.application.active
        });
    }

    onImplicitChange(implicit) {

        return this.updateApplication({
            implicit
        });
    }

    onPkceChanged(pkceEnabled) {

        return this.updateApplication({ pkceEnabled });
    }

    onOverrideEmailValidationChanged(overrideEmailValidation) {
        return this.updateApplication({ overrideEmailValidation });
    }

    onPasswordChanged(passwordEnabled) {

        return this.updateApplication({ passwordEnabled });
    }

    onPrivacyPolicyUpdated(link) {

        return this.updateApplication({
            displayable: {
                privacyPolicyUrl: link
            }
        }, 'Privacy policy link updated.');
    }

    onTermsAndConditionsUpdated(link) {

        return this.updateApplication({
            displayable: {
                termsAndConditionsUrl: link
            }
        }, 'Terms and conditions link updated.');
    }

    onTitleUpdated(title) {

        return this.updateApplication({
            displayable: {
                content: {
                    title
                }
            }
        }, 'Portal title updated.');
    }

    onAppIconUpdated(appIconUrl) {

        return this.updateApplication({
            displayable: {
                content: {
                    appIconUrl
                }
            }
        }, 'Application icon updated.');
    }

    onMoreInfoUpdated(link) {

        return this.updateApplication({
            displayable: {
                content: {
                    moreInfoUrl: link
                }
            }
        }, '"More Information" link updated.');
    }

    onSuccess(message) {

        this.setState({ success: message, error: null });
    }

    onFailed(message) {

        this.setState({ success: null, error: message });
    }

    onUpdateStatus(type, message) {

        if (type === 'error') {
            return this.onFailed(message);
        }
        return this.onSuccess(message);
    }

    onPermissionAdded(permission) {

        const permissions = this.state.application.permissions.slice();
        permissions.push(permission);

        return this.updateApplication({ permissions });
    }

    onPermissionRemoved(permission) {

        const permissions = this.state.application.permissions.slice();
        const index = permissions.findIndex((p) => { return p === permission; });
        if (index >= 0) {
            permissions.splice(index, 1);
            return this.updateApplication({ permissions });
        }
    }

    componentDidMount() {

        this.api.exec(`/user/organizations/${this.props.organizationId}/applications/${this.props.applicationId}`)
        .then((application) => {

            this.api.exec('/providers')
            .then((providers) => {

                this.setState({ application, providers, error: null, success: null });
            })
            .catch((err) => {

                this.onFailed(err.toString());
            })

        })
        .catch((err) => {

            this.onFailed(err.toString());
        });
    }

    render() {

        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect} />);
        }

        const {
            application,
            providers
        } = this.state;

        if (!application) {
            return null;
        }

        return (
            <div className="container container-fluid">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>{application.applicationName}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <FormError
                            width="10"
                            offset="1"
                            styles={{ marginTop: '2%' }}
                            message={this.state.success}
                            suppressTitle
                            state="success" />
                        <FormError
                            width="10"
                            offset="1"
                            styles={{ marginTop: '2%' }}
                            message={this.state.error} />
                    </div>
                </div>
                <div className="row" style={{ marginTop: '3%' }}>
                    <div className="col-4">
                        <AppStatusPanel application={application} />
                    </div>
                    <div className="col-8">
                        <AppManagePanel
                            application={application}
                            providers={providers}
                            onAppTypeChanged={this.onAppTypeChanged}
                            onPkceChanged={this.onPkceChanged}
                            onPasswordChanged={this.onPasswordChanged}
                            onOverrideEmailValidationChanged={this.onOverrideEmailValidationChanged}
                            onUpdateStatus={this.onUpdateStatus}
                            onDelegateChanged={this.onDelegateChanged}
                            onDelegateWhitelistChanged={this.onDelegateWhitelistChanged}
                            onDelegateWhitelistAdded={this.onDelegateWhitelistAdded}
                            onDelegateWhitelistRemoved={this.onDelegateWhitelistRemoved}
                            onDomainAdded={this.onDomainAdded}
                            onDomainRemoved={this.onDomainRemoved}
                            onChannelIdChanged={this.onChannelIdChanged}
                            onClientSecretReset={this.onClientSecretReset}
                            onProviderChanged={this.onProviderChanged}
                            onImplicitChange={this.onImplicitChange}
                            onPrivacyPolicyUpdated={this.onPrivacyPolicyUpdated}
                            onTermsAndConditionsUpdated={this.onTermsAndConditionsUpdated}
                            onTitleUpdated={this.onTitleUpdated}
                            onAppIconUpdated={this.onAppIconUpdated}
                            onMoreInfoUpdated={this.onMoreInfoUpdated}
                            onToggleApplication={this.onToggleApplication}
                            onDeleteApplication={this.onDeleteApplication}
                            onPermissionAdded={this.onPermissionAdded}
                            onPermissionRemoved={this.onPermissionRemoved}
                            />
                    </div>
                </div>
            </div>
        )
    }
}

export {
    ApplicationDetailApp
};
