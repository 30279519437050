import React from 'react';

class PortalContentCapsule extends React.Component {

    constructor(props) {

        super();
        this.state = {
            title: props.title,
            appIcon: props.appIcon
        };

        this.fieldUpdateMethods = {
            title: props.onTitleUpdated,
            appIcon: props.onAppIconUpdated
        };

        this.onKeyPressedGen = this.onKeyPressedGen.bind(this);
        this.onClickGen = this.onClickGen.bind(this);
    }

    onKeyPressedGen(fieldName) {
        return (e) => {
            return this.setState({ [fieldName]: e.target.value });
        };
    }

    onClickGen(fieldName) {
        return (e) => {
            return this.fieldUpdateMethods[fieldName](this.state[fieldName]);
        };
    }

    render() {

        return (
            <div style={{ marginTop: '3%', marginBottom: '3%' }} className="col-12">
                <div className="row">
                    <div className="col-12">
                        <span className="text-left">
                            <strong>Portal Content</strong>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div style={{ paddingLeft: '3%' }}>
                            <em>Portal Title</em>
                        </div>
                        <div className="row">
                            <div className="col-10">
                                <input type="text" className="form-control" value={this.state.title} onChange={this.onKeyPressedGen('title') } />
                            </div>
                            <div className="col-2">
                                <button className="btn btn-small btn-default" onClick={this.onClickGen('title')}><i className="fas fa-check"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div style={{ paddingLeft: '3%' }}>
                            <em>App Logo</em>
                        </div>
                        <div className="row">
                            <div className="col-10">
                                <input type="text" className="form-control" value={this.state.appIcon || ""} onChange={this.onKeyPressedGen('appIcon') } />
                            </div>
                            <div className="col-2">
                                <button className="btn btn-small btn-default" onClick={this.onClickGen('appIcon')}><i className="fas fa-check"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PortalContentCapsule;
