import React, { Component } from 'react';
import AppContainer from '../../components/shared/app-container';
import { DetailContainer } from './components/detail-container';

class OrganizationDetail extends Component {

    constructor({ match }) {

        super();
        this.state = {
            id: match.params.organizationId
        };
    }

    render() {

        return (
            <AppContainer application={<DetailContainer id={this.state.id} />} />
        );
    }
}

export default OrganizationDetail;
