import React, { Component } from 'react';
import Api from '../../../components/shared/api';

class LoginHero extends Component {

    render() {

        return (
            <div className="jumbotron jumbotron-fluid title-jumbo">
                <div className="container">
                    <h1 className="display-3 text-center title-main title-fade-animate">GolfID</h1>
                    <h3 className="text-center subtitle-main subtitle-fade-animate">Administrative Portal</h3>
                </div>
            </div>
        );
    }
}

class LoginAlert extends Component {

    render() {

        if (!this.props.message) {
            return null;
        }

        return (
            <div className="row">
                <div className="alert alert-danger col-4 offset-4">
                    <div className="text-center"><strong>Bogey!</strong></div>
                    <div id="error-text" className="text-center">{this.props.message}</div>
                </div>
            </div>
        );
    }
}

class LoginForm extends Component {

    constructor() {

        super();
        this.state = {
            username: '',
            password: '',
            redirectToReferrer: false,
            error: null
        };
        this.api = new Api();
        this.loginClick = this.loginClick.bind(this);
        this.onEnterKey = this.onEnterKey.bind(this);
    }

    render() {

        return (
            <div className="row">
                <div className="col-4 offset-4">
                    <div className="form-group text-center">
                        <input autoFocus type="text" className="form-control" placeholder="Username" onKeyPress={this.onEnterKey} onChange={this.makeTypeCb('username')} />
                        <input type="password" className="form-control" placeholder="Password" onKeyPress={this.onEnterKey} onChange={this.makeTypeCb('password')} />
                        <button className="btn btn-default btn-outline-success my-2 my-sm-0 text-center" onClick={this.loginClick}>Login</button>
                    </div>
                </div>
            </div>
        )
    }

    makeTypeCb(objTarget) {

        return function(ev) {

            this.setState({
                [objTarget]: ev.target.value
            });
        }.bind(this);
    }

    onEnterKey(e) {

        if (e.key === 'Enter') {
            this.loginClick();
        }
    }

    loginClick() {

        this.api.exec('/auth', 'POST', { username: this.state.username, password: this.state.password })
        .then((response) => {

            if (response.error) {
                this.props.onFailed(response.message);
            }
            else {
                localStorage.setItem('gnid.admin', JSON.stringify(response));
                // localStorage.setItem('gnid.admin', { id: response.id, username: response.username });
                this.props.onSuccess();
            }
        })
        .catch((err) => {

            console.error(err);
            this.props.onFailed(err.toString());
        });
    }
}

class LoginContainer extends Component {

    constructor() {

        super();

        this.state = {
            message: null
        };
        this.onFailed = this.onFailed.bind(this);
    }

    componentDidMount() {

        switch (this.props.code) {
            case '401':
                this.setState({
                    message: 'You have been logged out. Please re-authenticate to log back in.'
                });
                break;
            default:
                break;
        }
    }

    onFailed(message) {

        this.setState({ message });
    }

    render() {

        return (
            <div className="container container-fluid">
                <LoginAlert message={this.state.message} />
                <LoginForm onSuccess={this.props.onSuccess} onFailed={this.onFailed} />
            </div>
        );
    }
}

export {
    LoginHero,
    LoginAlert,
    LoginForm,
    LoginContainer
};
