import React, { Component } from 'react';

class AppTypeSelect extends Component {

    constructor() {

        super();
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    onSelectChange(e) {

        this.props.onChange(e.target.value);
    }

    render() {

        return (
            <div className="form-group">
                <select className="form-control" id="app-type-select" defaultValue={this.props.selected || ""} onChange={this.onSelectChange}>
                    <option key="null" value="" disabled>Select an application type...</option>
                    <option key="web" value="web">Web Application</option>
                    <option key="spa" value="spa">Single-Page Application</option>
                    <option key="mobile" value="mobile">Mobile Application</option>
                </select>
            </div>
        );
    }
}

export default AppTypeSelect;
