import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom';
import SummaryHeader from './summary-header';

/*
    The data contract is:

    [{
        title: 'some title',
        data: 'some data',
        type: 'type' // 'date' causes formatting to occur.
        transform: function (input) { }; // if a transformer is specified,
                                         // data will be mutated before being
                                         // placed into the value field.
    }]
*/

class StatusCardEntry extends Component {

    constructor() {

        super();
        this.toPrintableDate = this.toPrintableDate.bind(this);
        this.finalize = this.finalize.bind(this);
        this.dataContractNotSatisfied = (
            <li className="list-group-item">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">ERROR</div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-right">&lt;data contract not satisfied&gt;</div>
                        </div>
                    </div>
                </div>
            </li>
        );
    }

    toPrintableDate(d) {

        const zpad = (n, z) => {

            n = n.toString();
            while (n.length < z) {
                n = '0' + n;
            }

            return n;
        };

        d = new Date(d);
        return `${zpad(d.getMonth() + 1, 2)}/${zpad(d.getDate() + 1, 2)}/${d.getFullYear()}`;
    }

    finalize(element, classes, styles, hover, link) {

        const finalized = link ?
        (
            <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
                {element}
            </Link>
        )
        :
        element;

        return (
            <li title={hover} className={`list-group-item ${classes || ''}`} style={styles}>
                {finalized}
            </li>
        )
    }

    render() {

        const {
            title,
            data,
            hover,
            type,
            classes,
            styles,
            link,
            transform
        } = this.props;

        let rendered = null;
        let linkUrl;

        if (!title || !data) {
            return this.dataContractNotSatisfied;
        }

        switch (type) {
            case 'date':
                rendered = this.toPrintableDate(data);
                break;
            default:
                rendered = data;
                break;
        }

        if (transform) {
            rendered = transform(rendered);
        }

        if (link) {
            if (typeof link === 'function') {
                linkUrl = link(data);
            }
            else {
                linkUrl = link;
            }
        }

        const elementCore = (
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">{title}</div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-right">{rendered}</div>
                    </div>
                </div>
            </div>
        );

        return this.finalize(elementCore, classes, styles, hover, linkUrl);
    }
}

// <StatusCard header="Whatever" data={data} />
class StatusCard extends Component {

    render() {

        return (
            <div className="container container-fluid">
                <ul className="list-group">
                    <SummaryHeader name={this.props.header} />
                    {this.props.data.map((d) => {

                        return (
                            <StatusCardEntry
                                key={d.title}
                                title={d.title}
                                hover={d.hover}
                                data={d.data}
                                type={d.type}
                                link={d.link}
                                classes={d.classes}
                                styles={d.styles}
                                transform={d.transform} />
                        );
                    })}
                </ul>
            </div>
        )
    }
}

export default StatusCard;
