import React from 'react';
import Modal from './modal';

class ImplicitCore extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            wasInitiallySet: this.props.implicit === true,
            implicit: this.props.implicit,
            warned: false,
            mustWarn: false,
        };

        this.onImplicitChange = this.onImplicitChange.bind(this);
        this.onModalConfirm = this.onModalConfirm.bind(this);
        this.onModalDeny = this.onModalDeny.bind(this);
    }

    onImplicitChange(implicit) {

        if (implicit && !this.state.wasInitiallySet) {
            if (!this.state.warned) {
                return this.setState({
                    mustWarn: true
                });
            }
        }

        this.setState({
            implicit
        });
        this.props.onChange(implicit);
    }

    onModalConfirm() {

        this.setState({
            mustWarn: false,
            warned: true,
            implicit: true
        });
        this.props.onChange(true);
    }

    onModalDeny() {

        this.setState({
            mustWarn: false,
            implicit: false
        });
    }

    render(component) {

        return (
            <div>
                <Modal
                    title="Confirm Implicit Status"
                    body={
                        <div>
                            <p>Setting an application to implicit means that the application will be allowed access to the user's account without their express permission.</p>
                            <p>This can have <strong>SERIOUS</strong> legal consequences in some jurisdictions, and should <strong>NOT</strong> be activated without considering the consequences as such.</p>
                            <p>Are you <strong>SURE</strong> you wish to mark this application implicit? Once you agree, you will not be prompted again.</p>
                        </div>
                    }
                    show={this.state.mustWarn}
                    onConfirm={this.onModalConfirm}
                    onDeny={this.onModalDeny}
                />
                {component}
            </div>
        )
    }
}

export default ImplicitCore;
