import React, { Component } from 'react';
import SummaryHeader from '../../../components/shared/summary-header';
import {
    Link
} from 'react-router-dom';

class ApplicationSummary extends Component {

    render() {

        const itemClasses = `list-group-item list-group-item-action ${this.props.active ? 'list-group-item-success' : 'list-group-item-danger' }`;
        return (
            <li className={itemClasses}>
                <Link to={`/organizations/${this.props.organizationId}/applications/${this.props.id}`}
                      style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div className="row">
                        <div className="col-9">
                            {this.props.name}
                        </div>
                        <div className="col-3 text-right">
                            <i className="fas fa-cog"></i>
                        </div>
                    </div>
                </Link>
            </li>
        );
    }
}

class ApplicationsSummary extends Component {

    render() {

        return (
            <ul className="list-group">
                <SummaryHeader name="Applications" create={`/organizations/${this.props.organizationId}/applications/create`} />
                { this.props.applications.length > 0 ? this.props.applications.map((a) => {
                    return <ApplicationSummary id={a._id}
                                               key={a._id}
                                               organizationId={this.props.organizationId}
                                               name={a.applicationName}
                                               active={a.active} />

                                       }) :
                                       <li className="list-group-item text-center disabled"><em>No applications</em></li>
                }
            </ul>
        );
    }
}

export default ApplicationsSummary;
