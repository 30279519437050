import React, { Component } from 'react';
import AppContainer from '../../components/shared/app-container';
import { UserDetailApp } from './components/user-detail-app';

class UserDetail extends Component {

    constructor({ match }) {

        super();
        this.state = {
            id: match.params.userId
        };
    }

    render() {

        return (
            <AppContainer application={<UserDetailApp id={this.state.id} />} />
        );
    }
}

export default UserDetail;
