import React, { Component } from 'react';
import AppHeader from './app-header';

class AppContainer extends Component {

    render() {

        return (
            <div className="container container-fluid">
                <AppHeader />
                {this.props.application}
            </div>
        );
    }
}

export default AppContainer;
