import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SummaryHeader from './summary-header';
import Api from './api';

class OrganizationListItem extends Component {

    render() {

        return (
            <li className="list-group-item">
                <Link to={`/organizations/${this.props.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div className="row">
                        <div className="col-9">
                            {this.props.name}
                        </div>
                        <div className="col-3 text-right">
                            <div className="row">
                                <div className="col-12">
                                    <i className="fas fa-cogs"></i>&nbsp;{this.props.applications}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <i className="fas fa-users"></i>&nbsp;{this.props.members}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </li>
        );
    }
}

class OrganizationList extends Component {

    constructor() {

        super();
        this.state = {
            organizations: []
        };
        this.api = new Api();
        this.asProps = this.asProps.bind(this);
        this.asState = this.asState.bind(this);
        this.provision = this.provision.bind(this);
    }

    componentDidMount() {

        if (this.props.href) {
            this.api.exec(this.props.href)
            .then((organizations) => {

                this.setState({ organizations });
            })
            .catch((err) => {

                console.error(err);
                alert(`An error occurred. Please refresh to try again.`);
            })
        }
    }

    provision(dataSource) {

        return (
            <ul className="list-group">
                <SummaryHeader name="Organizations" create={`/organizations/create`} />
                {dataSource.length > 0 ? dataSource.map((o) => {

                    return (
                        <OrganizationListItem id={o._id}
                                              key={o._id}
                                              name={o.name}
                                              applications={o.applications.length}
                                              members={o.members.length} />
                    )
                }) : <li className="list-group-item text-center"><em>No organizations.</em></li>}
            </ul>
        );
    }

    asProps() {

        return this.provision(this.props.organizations);
    }

    asState() {

        return this.provision(this.state.organizations);
    }

    render() {

        if (this.props.organizations) {
            return this.asProps();
        }
        return this.asState();
    }
}

export default OrganizationList;
