import React, { Component } from 'react';
import AppContainer from '../../components/shared/app-container';
import { HomeApp } from './components/home-app';

class Home extends Component {

    constructor(props) {

        super(props);
        this.state = {};
    }

    render() {
        return (
            <AppContainer application={<HomeApp />} />
        );
    }
}

export default Home;
