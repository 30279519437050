import React, { Component } from 'react';
import CreateForm from './create-form';

class UserCreateApp extends Component {

    render() {

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h3 className="text-center">Add a User</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <CreateForm />
                    </div>
                </div>
            </div>
        );
    }
}

export {
    UserCreateApp
};
