import React, { Component } from 'react';
import AppContainer from '../../components/shared/app-container';
import { ApplicationDetailApp } from './components/application-detail-app';

class ApplicationDetail extends Component {

    constructor({ match }) {

        super();
        this.state = {
            organizationId: match.params.organizationId,
            applicationId: match.params.applicationId
        };
    }

    render() {

        return <AppContainer application={<ApplicationDetailApp organizationId={this.state.organizationId} applicationId={this.state.applicationId} />} />
    }
}

export default ApplicationDetail;
