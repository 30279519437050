import React, { Component } from 'react';

class FormError extends Component {

    render() {

        if (!this.props.message) {
            return null;
        }

        const width = this.props.width || '4';
        const offset = this.props.offset || '4';
        const state = this.props.state || 'danger';
        const styles = this.props.styles || null;
        const classes = this.props.classes || `alert alert-${state} col-${width} offset-${offset}`;
        const title = this.props.suppressTitle === undefined ?
                      (<div className="text-center"><strong>{this.props.title || 'Error'}</strong></div>)
                      :
                      null;
        return (
            <div className="row">
                <div style={styles} className={classes}>
                    {title}
                    <div id="error-text" className="text-center">{this.props.message}</div>
                </div>
            </div>
        );
    }
}

export default FormError;
