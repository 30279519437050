import React, { Component } from 'react';

class IP extends Component {

    constructor() {

        super();
        this.onRemove = this.onRemove.bind(this);
    }

    onRemove() {

        return this.props.onRemove(this.props.url);
    }

    render() {

        return (
            <li className="list-group-item">
                <div className="row">
                    <div className="col-6">
                        {this.props.url}
                    </div>
                    <div className="col-6 text-right">
                        <span style={{ cursor: 'pointer' }} onClick={this.onRemove}>
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </li>
        );
    }
}

class DelegateSettings extends Component {

    constructor(props) {

        super(props);
        this.state = {
            ip: ''
        };
        this.onChecked = this.onChecked.bind(this);
        this.onWhitelistChecked = this.onWhitelistChecked.bind(this);
        this.onNewDomainKeyed = this.onNewDomainKeyed.bind(this);
        this.onNewDomainPossiblySubmitted = this.onNewDomainPossiblySubmitted.bind(this);
    }

    onNewDomainKeyed(e) {

        this.setState({ ip: e.target.value });
    }

    onNewDomainPossiblySubmitted(e) {

        if (e.key === 'Enter') {
          console.log(this.state.ip.slice());
            this.props.onAdd(this.state.ip.slice());
            this.setState({ ip: '' });
        }
    }

    onChecked(e) {

        this.props.onChange(e.target.checked);
    }

    onWhitelistChecked(e) {

        this.props.onWhitelistChange(e.target.checked);
    }

    render() {
        let placeholder = 'White list an IP';
        let whitelistEnabled = null;
        let whitelist = null;

        if (this.props.delegate.whitelist.length > 0) {
          placeholder = 'White list another IP';
        }

        if (this.props.delegate.whitelistEnabled) {

        }

        if (this.props.delegate.enabled) {
          whitelist = (
            <div>
            <div className="form-check">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input" checked={this.props.delegate.whitelistEnabled} onChange={this.onWhitelistChecked} />
                Enable Whitelist
              </label>
            </div>
            <div className="row">
              <div className="col-12">
                  <span className="text-center"><strong>Whitelist</strong></span>
                  <div className="row">
                      <div className="col-12">
                          <ul className="list-group">
                              {
                                  this.props.delegate.whitelist.map((u) => {

                                      return <IP key={u} url={u} onRemove={this.props.onRemove}/>
                                  })
                              }
                              <li className="list-group-item">
                                  <style dangerouslySetInnerHTML={{__html: `
                                      .no-lines:focus {
                                          outline: none;
                                          border-color: inherit;
                                          -webkit-box-shadow: none;
                                          box-shadow: none;
                                      }
                                  `}}>
                                  </style>
                                  <input type="text"
                                         value={this.state.ip}
                                         className="form-control no-lines"
                                         style={{ border: 'none' }}
                                         placeholder={placeholder}
                                         onChange={this.onNewDomainKeyed}
                                         onKeyPress={this.onNewDomainPossiblySubmitted}
                                         />
                              </li>
                          </ul>
                      </div>
                      <div className="col-6">
                      </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }

        return (
          <div>
            <div className="form-check">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input" checked={this.props.delegate.enabled} onChange={this.onChecked} />
                Enable Delegated Trust
              </label>
            </div>
            {whitelist}
          </div>
        );
    }
}

export default DelegateSettings;
