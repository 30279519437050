import React, { Component } from 'react';
import AppContainer from '../../components/shared/app-container';
import { MembersAddApp } from './components/members-add-app';

class MembersAdd extends Component {

    constructor({ match }) {

        super();
        this.state = {
            organizationId: match.params.organizationId
        };
    }

    render() {
        return (
            <AppContainer application={<MembersAddApp organizationId={this.state.organizationId} />} />
        );
    }
}

export default MembersAdd;
