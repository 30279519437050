import React from 'react';

class LinksCapsule extends React.Component {

    constructor(props) {

        super();

        this.state = {
            privacyPolicyUrl: props.privacyPolicyUrl,
            termsAndConditionsUrl: props.termsAndConditionsUrl,
            moreInfoUrl: props.moreInfoUrl
        };

        this.fieldUpdateMethods = {
            privacyPolicyUrl: props.onPrivacyPolicyUpdated,
            termsAndConditionsUrl: props.onTermsAndConditionsUpdated,
            moreInfoUrl: props.onMoreInfoUpdated
        };

        this.onKeyPressedGen = this.onKeyPressedGen.bind(this);
        this.onClickGen = this.onClickGen.bind(this);
    }

    onClickGen(fieldName) {

        return (e) => {

            return this.fieldUpdateMethods[fieldName](this.state[fieldName]);
        };
    }

    onKeyPressedGen(fieldName) {

        return (e) => {
            this.setState({ [fieldName]: e.target.value });
        };
    }

    render() {

        return (
            <div style={{ marginBottom: '1%' }} className="col-12">
                <div className="row">
                    <div className="col-12">
                        <span className="text-left">
                            <strong>Links</strong>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div style={{ paddingLeft: '3%' }}><em>Privacy Policy</em></div>
                        <div className="row">
                            <div className="col-10">
                                <input type="text" className="form-control" value={this.state.privacyPolicyUrl} onChange={this.onKeyPressedGen('privacyPolicyUrl')} />
                            </div>
                            <div className="col-2 pull-left">
                                <button className="btn btn-small btn-default" onClick={this.onClickGen('privacyPolicyUrl')}><i className="fas fa-check"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div style={{ paddingLeft: '3%' }}><em>Terms and Conditions</em></div>
                        <div className="row">
                            <div className="col-10">
                                <input type="text" className="form-control" value={this.state.termsAndConditionsUrl} onChange={this.onKeyPressedGen('termsAndConditionsUrl')} />
                            </div>
                            <div className="col-2 pull-left">
                                <button className="btn btn-small btn-default" onClick={this.onClickGen('termsAndConditionsUrl')}><i className="fas fa-check"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div style={{ paddingLeft: '3%' }}><em>More Information</em></div>
                        <div className="row">
                            <div className="col-10">
                                <input type="text" className="form-control" value={this.state.moreInfoUrl} onChange={this.onKeyPressedGen('moreInfoUrl')} />
                            </div>
                            <div className="col-2 pull-left">
                                <button className="btn btn-small btn-default" onClick={this.onClickGen('moreInfoUrl')}><i className="fas fa-check"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LinksCapsule;
