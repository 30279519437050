import React, { Component } from 'react';
import {
    Redirect
} from 'react-router-dom';
import UserSwitchList from '../../../components/shared/user-switch-list';
import FormError from '../../../components/shared/form-error';
import Api from '../../../components/shared/api';

class CreateForm extends Component {

    constructor() {

        super();
        this.state = {
            error: null,
            redirect: null,
            usersOut: [],
            usersIn: []
        };
        this.onUserInChange = this.onUserInChange.bind(this);
        this.onUserOutChange = this.onUserOutChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.api = new Api();
    }

    componentDidMount() {

        const usersIn = [];
        const usersOut = [];

        this.api.exec(`/user/organizations/${this.props.organizationId}`)
        .then((organization) => {

            this.api.exec(`/users`)
            .then((users) => {

                for (let i = 0; i < organization.members.length; ++i) {
                    usersIn.push({
                        id: organization.members[i]._id,
                        name: organization.members[i].username,
                        immutable: organization.members[i]._id === organization.createdBy._id
                    });
                }

                for (let i = 0; i < users.length; ++i) {
                    const index = usersIn.findIndex((u) => {

                        return u.id === users[i]._id;
                    });

                    if (index < 0) {
                        usersOut.push({
                            id: users[i]._id,
                            name: users[i].username
                        });
                    }
                }

                this.setState({ usersIn, usersOut });
            })
            .catch((err) => {

                this.setState({ error: err.toString() });
            });
        })
        .catch((err) => {

            this.setState({ error: err.toString () });
        });
    }

    userExchange(fromArrName, toArrName, id) {

        const fromArr = this.state[fromArrName];
        const toArr = this.state[toArrName];

        const fromArrIndex = fromArr.findIndex((f) => { return f.id === id; });
        if (fromArrIndex >= 0) {
            const fromArrItem = fromArr.splice(fromArrIndex, 1)[0];
            toArr.push(fromArrItem);

            this.setState({
                [fromArrName]: fromArr,
                [toArrName]: toArr
            });
        }
    }

    onUserOutChange(id) {

        this.userExchange('usersOut', 'usersIn', id);
    }

    onUserInChange(id) {

        this.userExchange('usersIn', 'usersOut', id);
    }

    onSubmitClick() {

        const payload = {
            members: this.state.usersIn.map((u) => { return u.id; })
        };

        this.api.exec(`/user/organizations/${this.props.organizationId}`, 'PUT', payload)
        .then((result) => {

            if (result.error) {
                return this.setState({ error: result.message });
            }

            return this.setState({
                redirect: `/organizations/${this.props.organizationId}?updated=true`
            });
        })
        .catch((err) => {

            this.setState({ error: err.toString() });
        });
    }

    render() {

        const { redirect, error } = this.state;

        if (redirect) {
            return (<Redirect to={redirect} />);
        }

        return (
            <div className="row" style={{ marginTop: '2%' }}>
                <div className="col-12">
                    <FormError message={error} />
                </div>
                <div className="col-12">
                    <UserSwitchList style={{ marginTop: '2%'}}
                                    outTitle="Available"
                                    inTitle="To Be Added"
                                    outDirection="right"
                                    inDirection="left"
                                    usersOut={this.state.usersOut}
                                    usersIn={this.state.usersIn}
                                    onUserOutChange={this.onUserOutChange}
                                    onUserInChange={this.onUserInChange} />
                    <div className="row" style={{ marginTop: '2%' }}>
                        <div className="col-12 text-center">
                            <button className="btn btn-primary" onClick={this.onSubmitClick}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default CreateForm;
