import React, { Component } from 'react';
import {
    Route,
    Switch
} from 'react-router-dom';

import PrivateRoute from './components/system/private-route';
import Home from './pages/home/index';
import Login from './pages/login/index';
import UserCreate from './pages/user-create/index';
import UserDetail from './pages/user-detail/index';
import OrganizationCreate from './pages/organization-create/index';
import ApplicationCreate from './pages/application-create/index';
import OrganizationDetail from './pages/organization-detail/index';
import MembersAdd from './pages/members-add/index';
import ApplicationDetail from './pages/application-detail/index';

const Main = () => (
    <main>
        <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/users/create" component={UserCreate} />
            <PrivateRoute exact path="/organizations/create" component={OrganizationCreate} />
            <PrivateRoute path="/users/:userId" component={UserDetail} />
            <PrivateRoute path="/organizations/:organizationId/applications/create" component={ApplicationCreate} />
            <PrivateRoute path="/organizations/:organizationId/applications/:applicationId" component={ApplicationDetail} />
            <PrivateRoute path="/organizations/:organizationId/members/add" component={MembersAdd} />
            <PrivateRoute path="/organizations/:organizationId" component={OrganizationDetail} />
        </Switch>
    </main>
);

class App extends Component {
  render() {
    return (
      <div className="App">
          <Main />
      </div>
    );
  }
}

export default App;
