import React, { Component } from 'react';

class Modal extends Component {

    constructor(props) {

        super(props);
        this.state = {
            fadeOut: false,
            visibility: 'visible',
            duration: parseInt(this.props.fadeDuration, 10) || 10,
            closeFn: null
        };
        this.onConfirm = this.onConfirm.bind(this);
        this.onDeny = this.onDeny.bind(this);
    }

    onConfirm() {

        (this.props.onConfirm || function() {})();
    }

    onDeny() {

        (this.props.onDeny || function() {})();
    }

    render() {

        if (!this.props.show) {
            return null;
        }

        // const confirm = this.props.onConfirm || function() {};
        // const deny = this.props.onDeny || function() {};
        const bg = {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0,0,0,0.3)',
            padding: 50,
            zIndex: 20000
        };
        const $window = {
            backgroundColor: '#fff',
            borderRadius: 5,
            maxWidth: 500,
            minHeight: 100,
            margin: '0 auto',
            padding: 30,
            zIndex: 20001
        };

        return (
            <div className="oauth-modal-background" style={bg}>
                <div className="oauth-modal" style={$window}>
                    <div className="container container-fluid">
                        <div className="row oauth-modal-close">
                            <div className="col-12 text-right">
                                <button className="close" onClick={this.onDeny}>
                                    <span>&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="row oauth-modal-title text-left">
                            <div className="col-12">
                                <h5>{this.props.title}</h5>
                            </div>
                        </div>
                        <div className="row oauth-modal-body text-left">
                            <div className="col-12">
                                {this.props.body}
                            </div>
                        </div>
                        <div className="row oauth-modal-controls">
                            <div className="col-12 text-right">
                                <button type="button" className="btn btn-danger" onClick={this.onConfirm}>{this.props.confirmText || 'Yes'}</button>&nbsp;
                                <button type="button" className="btn btn-secondary" onClick={this.onDeny}>{this.props.denyText || 'No'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;
