import React, { Component } from 'react';
import AppContainer from '../../components/shared/app-container';
import { OrganizationCreateApp } from './components/organization-create-app';

class OrganizationCreate extends Component {

    constructor(props) {

        super(props);
        this.state = {};
    }

    render() {
        return (
            <AppContainer application={<OrganizationCreateApp />} />
        );
    }
}

export default OrganizationCreate;
