import React, { Component } from 'react';
import {
    Redirect
} from 'react-router-dom';
import UserSwitchList from '../../../components/shared/user-switch-list';
import FormError from '../../../components/shared/form-error';
import Api from '../../../components/shared/api';

class CreateForm extends Component {

    constructor() {

        super();
        this.state = {
            error: null,
            redirect: null,
            name: '',
            linkedTrust: false,
            usersOut: [],
            usersIn: []
        };

        this.onNameChange = this.onNameChange.bind(this);
        this.onUserInChange = this.onUserInChange.bind(this);
        this.onUserOutChange = this.onUserOutChange.bind(this);
        this.onLinkedTrustChange = this.onLinkedTrustChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.api = new Api();
    }

    componentDidMount() {

        const usersIn = [];
        const usersOut = [];

        this.api.exec('/user')
        .then((me) => {

            usersIn.push({
                id: me._id,
                name: me.username,
                immutable: true
            });

            this.api.exec('/users')
            .then((users) => {

                for (let i = 0; i < users.length; ++i) {
                    if (users[i]._id !== me._id) {
                        usersOut.push({
                            id: users[i]._id,
                            name: users[i].username,
                            immutable: false
                        });
                    }
                }
                this.setState({ usersIn, usersOut });
            })
            .catch((err) => {

                this.setState({ error: err.toString() });
            });
        })
        .catch((err) => {

            this.setState({ error: err.toString() });
        })
    }

    onNameChange(e) {

        this.setState({ name: e.target.value });
    }

    userExchange(fromArrName, toArrName, id) {

        const fromArr = this.state[fromArrName];
        const toArr = this.state[toArrName];

        const fromArrIndex = fromArr.findIndex((f) => { return f.id === id; });
        if (fromArrIndex >= 0) {
            const fromArrItem = fromArr.splice(fromArrIndex, 1)[0];
            toArr.push(fromArrItem);

            this.setState({
                [fromArrName]: fromArr,
                [toArrName]: toArr
            });
        }
    }

    onUserOutChange(id) {

        this.userExchange('usersOut', 'usersIn', id);
    }

    onUserInChange(id) {

        this.userExchange('usersIn', 'usersOut', id);
    }

    onLinkedTrustChange() {

        this.setState({ linkedTrust: !this.state.linkedTrust });
    }

    onSubmitClick() {

        if (this.state.name === '') {
            return this.setState({ error: 'You must provide a name.' });
        }

        const payload = {
            name: this.state.name,
            members: this.state.usersIn.map((u) => { return u.id; }),
            linkedTrust: this.state.linkedTrust
        };

        this.api.exec('/user/organizations', 'POST', payload)
        .then((result) => {

            if (result.error) {
                return this.setState({ error: result.message });
            }

            return this.setState({
                redirect: `/organizations/${result._id}`
            });
        })
        .catch((err) => {

            this.setState({ error: err.toString() });
        });
    }

    render() {

        const { redirect, error } = this.state;

        if (redirect) {
            return (<Redirect to={redirect} />);
        }

        return (
            <div className="row" style={{ marginTop: '2%' }}>
                <div className="col-12">
                    <FormError message={error} />
                </div>
                <div className="col-12">
                    <div className="row">
                        <input type="text" className="form-control" placeholder="Organzation Name" onChange={this.onNameChange} />
                    </div>
                    <UserSwitchList style={{ marginTop: '2%'}}
                                    outTitle="Available"
                                    inTitle="To Be Added"
                                    outDirection="right"
                                    inDirection="left"
                                    usersOut={this.state.usersOut}
                                    usersIn={this.state.usersIn}
                                    onUserOutChange={this.onUserOutChange}
                                    onUserInChange={this.onUserInChange} />
                    <div className="row" style={{ marginTop: '2%' }}>
                        <div className="col-12">
                            <label>
                                <input type="checkbox" onClick={this.onLinkedTrustChange} />
                                &nbsp;&nbsp;&nbsp;Enable Linked Trust
                            </label>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '2%' }}>
                        <div className="col-12 text-center">
                            <button className="btn btn-primary" onClick={this.onSubmitClick}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default CreateForm;
