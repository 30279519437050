import React, { Component } from 'react';
import Modal from '../../../components/shared/modal';
import ImplicitButton from '../../../components/shared/implicit-button';

class AppControlsPanel extends Component {

    constructor() {

        super();
        this.state = {
            show: false
        };

        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.onDeleteDeny = this.onDeleteDeny.bind(this);
    }

    onDeleteClick() {

        this.setState({ show: true });
    }

    onDeleteConfirm() {

        this.props.onDeleteApplication();
    }

    onDeleteDeny() {

        this.setState({ show: false });
    }

    render() {

        const buttonSpacing = { marginTop: '5%', marginBottom: '5%' };

        return (
            <div className="row">
                <div className="col-12">
                    <h5 className="text-center">Danger Zone</h5>
                    <div className="row">
                        <div className="col-12 text-center">
                            <Modal
                                title="Delete Application?"
                                body="Are you SURE you want to delete this application? This is permanent and cannot be undone!"
                                show={this.state.show}
                                onConfirm={this.onDeleteConfirm}
                                onDeny={this.onDeleteDeny} />
                            <div className="row" style={buttonSpacing}>
                                <div className="col-12 text-center">
                                    <button className={`btn btn-sm btn-${this.props.applicationActiveState ? 'danger' : 'success' }`} onClick={this.props.onToggleApplication}>
                                        {this.props.applicationActiveState ? 'Disable Application' : 'Enable Application'}
                                    </button>
                                </div>
                            </div>
                            <div className="row" style={buttonSpacing}>
                                <div className="col-12 text-center">
                                    <button className="btn btn-danger btn-sm" onClick={this.onDeleteClick}>Delete Application</button>
                                </div>
                            </div>
                            <div className="row" style={buttonSpacing}>
                                <div className="col-12 text-center">
                                    <ImplicitButton implicit={this.props.applicationImplicitState} onChange={this.props.onImplicitChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AppControlsPanel;
