import React, { Component } from 'react';
import Modal from '../../../components/shared/modal';
import SummaryHeader from '../../../components/shared/summary-header';
import {
    Link
} from 'react-router-dom';

class AccessSecret extends Component {

    constructor() {

        super();
        this.state = {
            shown: false
        };

        this.onReveal = this.onReveal.bind(this);
    }

    onReveal() {

        this.setState({ shown: !this.state.shown });
    }

    render() {

        let secret = '';

        if (!this.props.secret) {
            return (<span>&lt;unset&gt;&nbsp;<i className="fas fa-eye" style={{ visibility: 'hidden' }}></i></span>);
        }

        if (!this.state.shown) {
            for (let i = 0; i < this.props.secret.length; ++i) {
                secret += '•';
            }
        }
        else {
            secret = this.props.secret;
        }

        return (
            <span>
                {secret}&nbsp;
                <span onClick={this.onReveal} style={{ display: this.state.shown ? 'none' : 'inherit' }} className="text-right">
                    <i className="fas fa-eye"></i>
                </span>
                <span onClick={this.onReveal} style={{ display: !this.state.shown ? 'none' : 'inherit' }} className="text-right">
                    <i className="fas fa-eye-slash"></i>
                </span>
            </span>
        );
    }
}

class CredentialsSummary extends Component {

    constructor() {

        super();
        this.state = {
            modalChangeSecret: false,
            modalDeleteCredentials: false,
        };

        this.onChangeSecretConfirm = this.onChangeSecretConfirm.bind(this);
        this.onChangeSecretDeny = this.onChangeSecretDeny.bind(this);
        this.onDeleteCredentialsConfirm = this.onDeleteCredentialsConfirm.bind(this);
        this.onDeleteCredentialsDeny = this.onDeleteCredentialsDeny.bind(this);
        this.onGenerateCredentials = this.onGenerateCredentials.bind(this);
        this.warnBeforeChangingSecret = this.warnBeforeChangingSecret.bind(this);
        this.warnBeforeDeletingCredentials = this.warnBeforeDeletingCredentials.bind(this);
    }

    onChangeSecretConfirm() {

        this.props.onChangeSecret();
        this.setState({ modalChangeSecret: false });
    }

    onChangeSecretDeny() {

        this.setState({ modalChangeSecret: false });
    }

    onDeleteCredentialsConfirm() {

        this.props.onDeleteCredentials();
        this.setState({ modalDeleteCredentials: false });
    }

    onDeleteCredentialsDeny() {

        this.setState({ modalDeleteCredentials: false });
    }

    onGenerateCredentials() {
        this.props.onGenerateCredentials();
    }

    warnBeforeChangingSecret() {

        this.setState({ modalChangeSecret: true });
    }

    warnBeforeDeletingCredentials() {

        this.setState({ modalDeleteCredentials: true });
    }

    render() {

        const hasCredentials = this.props.credentials && this.props.credentials.accessKey ? true : false;
        
        let credentials = (
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td className="text-right">
                            <button className="btn btn-success btn-sm" onClick={this.onGenerateCredentials}>Generate Credentials</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        );

        if (hasCredentials) {
            credentials = (
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td><strong>Access Key</strong></td>
                            <td className="text-right">
                                {this.props.credentials.accessKey || '<unset>'}
                                &nbsp;<i style={{ visibility: 'hidden' }} className="fas fa-eye"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Access Secret</strong>
                            </td>
                            <td className="text-right">
                                <AccessSecret secret={this.props.credentials.accessSecret} />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingTop: '3%' }} className="text-left">
                                <Modal
                                    title="Delete Organization Credentials"
                                    body={
                                        <div>
                                            <p>Deleting an Organization's credentials will cause all integrated clients to fail while requesting organization endpoints.</p>
                                            <p>This <strong>CANNOT</strong> be undone!</p>
                                            <p>Are you sure you want to proceed?</p>
                                        </div>
                                    }
                                    show={this.state.modalDeleteCredentials}
                                    onConfirm={this.onDeleteCredentialsConfirm}
                                    onDeny={this.onDeleteCredentialsDeny} />
                                <button className="btn btn-warning btn-sm" onClick={this.warnBeforeDeletingCredentials}>Delete Credentials</button></td>
                            <td style={{ paddingTop: '3%' }} className="text-right">
                                <Modal
                                    title="Change Organization Secret"
                                    body={
                                        <div>
                                            <p>Changing an Organization's secret will cause all integrated clients to fail while requesting organization endpoints.</p>
                                            <p>This <strong>CANNOT</strong> be undone!</p>
                                            <p>Are you sure you want to proceed?</p>
                                        </div>
                                    }
                                    show={this.state.modalChangeSecret}
                                    onConfirm={this.onChangeSecretConfirm}
                                    onDeny={this.onChangeSecretDeny} />
                                <button className="btn btn-danger btn-sm" onClick={this.warnBeforeChangingSecret}>Reset Access Secret</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }

        // if (hasCredentials)

        return (
            <ul className="list-group">
                <SummaryHeader name="Credentials" />
                <li className="list-group-item">
                    <div className="row">
                        <div className="col-12">
                            {credentials}
                        </div>
                    </div>
                </li>
            </ul>
        );
    }
}

export default CredentialsSummary;
