import React from 'react';
import SummaryHeader from '../../../components/shared/summary-header';

class OrganizationSettings extends React.Component {

    constructor(props) {

        super();

        this.onLinkedTrustClicked = this.onLinkedTrustClicked.bind(this);
    }

    onLinkedTrustClicked() {

        this.props.onLinkedTrustClicked();
    }

    render() {

        return (
            <ul style={{ marginTop: '2%' }} className="list-group">
                <SummaryHeader name="Settings" />
                <li className="list-group-item text-center">
                    <label>
                        <input type="checkbox" checked={this.props.linkedTrust ? 'checked' : ''} onClick={this.onLinkedTrustClicked} />
                        &nbsp;&nbsp;&nbsp;Enable Linked Trust
                    </label>
                </li>
            </ul>
        );
    }
}

export default OrganizationSettings;
