import React, { Component } from 'react';
import Modal from '../../../components/shared/modal';

class ClientSecret extends Component {

    constructor() {

        super();
        this.state = {
            shown: false
        };

        this.onReveal = this.onReveal.bind(this);
    }

    onReveal() {

        this.setState({ shown: !this.state.shown });
    }

    render() {

        let secret = '';

        if (!this.props.secret) {
            return (<span>&lt;unset&gt;&nbsp;<i className="fas fa-eye" style={{ visibility: 'hidden' }}></i></span>);
        }

        if (!this.state.shown) {
            for (let i = 0; i < this.props.secret.length; ++i) {
                secret += '•';
            }
        }
        else {
            secret = this.props.secret;
        }

        return (
            <span>
                {secret}&nbsp;
                <span onClick={this.onReveal} style={{ display: this.state.shown ? 'none' : 'inherit' }} className="text-right">
                    <i className="fas fa-eye"></i>
                </span>
                <span onClick={this.onReveal} style={{ display: !this.state.shown ? 'none' : 'inherit' }} className="text-right">
                    <i className="fas fa-eye-slash"></i>
                </span>
            </span>
        );
    }
}

class AppCredentialsPanel extends Component {

    constructor() {

        super();
        this.state = {
            modalShown: false
        };

        this.warnBeforeChangingSecret = this.warnBeforeChangingSecret.bind(this);
        this.onChangeSecretConfirm = this.onChangeSecretConfirm.bind(this);
        this.onChangeSecretDeny = this.onChangeSecretDeny.bind(this);
    }

    warnBeforeChangingSecret() {

        this.setState({ modalShown: true });
    }

    onChangeSecretConfirm() {

        this.props.onReset();
        this.setState({ modalShown: false });
    }

    onChangeSecretDeny() {

        this.setState({ modalShown: false });
    }

    render() {

        return (
            <div className="row">
                <div className="col-12">
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td><strong>Client ID</strong></td>
                                <td className="text-right">
                                    {this.props.credentials.clientId || '<unset>'}
                                    &nbsp;<i style={{ visibility: 'hidden' }} className="fas fa-eye"></i>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Client Secret</strong>
                                </td>
                                <td className="text-right">
                                    <ClientSecret secret={this.props.credentials.clientSecret} />
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style={{ paddingTop: '3%' }} className="text-right">
                                    <Modal
                                        title="Change Application Secret"
                                        body={
                                            <div>
                                                <p>Changing an application's secret will cause all integrated clients to fail while requesting tokens from GolfID.</p>
                                                <p>This <strong>CANNOT</strong> be undone!</p>
                                                <p>Are you sure you want to procede?</p>
                                            </div>
                                        }
                                        show={this.state.modalShown}
                                        onConfirm={this.onChangeSecretConfirm}
                                        onDeny={this.onChangeSecretDeny} />
                                    <button className="btn btn-danger btn-sm" onClick={this.warnBeforeChangingSecret}>Reset Client Secret</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default AppCredentialsPanel;
