import React, { Component } from 'react';
import ProviderSelect from '../../../components/shared/provider-select';

class ProviderCapsule extends Component {

    render() {

        return (
            <div className="row" style={{ marginTop: '2%' }}>
                <div className="col-12">
                    <span className="text-left">
                        <strong>Auth Provider</strong>
                    </span>
                    <ProviderSelect
                        providers={this.props.providers}
                        onProviderChange={this.props.onChange}
                        disableNull="true"
                        selected={this.props.selected} />
                </div>
            </div>
        );
    }
}

export default ProviderCapsule
