import React, { Component } from 'react';
import SummaryHeader from '../../../components/shared/summary-header';
import PasswordUpdatePanel from './password-update-panel';

class UserManagementPanel extends Component {

    render() {

        return (
            <ul className="list-group">
                <SummaryHeader name="Manage" />
                <PasswordUpdatePanel id={this.props.id} onUserAltered={this.props.onUserAltered} />
            </ul>
        )
    }
}

export default UserManagementPanel;
