import React, { Component } from 'react';
import Api from '../../../components/shared/api';
import FormError from '../../../components/shared/form-error';

class PasswordUpdatePanel extends Component {

    constructor() {

        super();
        this.state = {
            password: '',
            repeat: '',
            updated: false,
            error: null
        };

        this.createStateUpdater = this.createStateUpdater.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.api = new Api();
    }

    createStateUpdater(name) {

        return function(e) {

            this.setState({
                [name]: e.target.value
            });
        }.bind(this);
    }

    onUpdate() {

        const {
            password,
            repeat
        } = this.state;

        if (password.length === 0) {
            return this.setState({ error: 'Enter a password.' });
        }

        if (password.length < 8) {
            return this.setState({ error: 'Password must be 8 or more characters.' });
        }

        if (repeat.length === 0) {
            return this.setState({ error: 'Re-enter your password.' });
        }

        if (password !== repeat) {
            return this.setState({ error: 'Passwords do not match.' });
        }

        this.api.exec(`/users/${this.props.id}`, 'PUT', { password })
        .then((updated) => {

            if (updated.error) {
                return this.setState({ error: updated.message });
            }

            if (updated.token) {
                console.log('for some reason i am in the kill block?');
                localStorage.removeItem('gnid.admin');
                localStorage.setItem('gnid.admin', updated.token);
                this.props.onUserAltered(updated.profile);
            }

            this.setState({ updated: true });
        })
        .catch((err) => {

            this.setState({ error: err.toString() });
        })
    }

    render() {

        const {
            error,
            updated
        } = this.state;
        let formError;

        if (error) {
            formError = (<FormError width="12" offset="0" message={this.state.error} />);
        }
        else if (updated) {
            formError = (<FormError width="12" offset="0" state="success" title="Success" message="Updated!" />);
        }

        return (
            <li className="list-group-item">
                <div className="row">
                    <div className="col-12 text-center" style={{ marginBottom: '1%' }}>
                        <h5 className="text-center">Update Password</h5>
                    </div>
                </div>
                {formError}
                <div className="row">
                    <div className="col-8">
                        <input type="password" placeholder="Password" className="form-control" onChange={this.createStateUpdater('password')} />
                    </div>
                    <div className="col-4 text-right">
                        <button className="btn btn-primary" onClick={this.onUpdate}>Update</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <input type="password" placeholder="Repeat Password" className="form-control" onChange={this.createStateUpdater('repeat')} />
                    </div>
                </div>
            </li>
        )
    }
}

export default PasswordUpdatePanel;
