import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom';
import LogoutButton from './logout-button';

class AppHeader extends Component {

    render() {

        const profile = JSON.parse(localStorage.getItem('gnid.admin') || {});

        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="/">GolfID</a>
                <div id="navbarNavDropdown" className="navbar-collapse collapse">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <div className="input-group">
                                <Link to={`/users/${profile.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <div className="input-group-addon">{profile.username}&nbsp;&nbsp;</div>
                                </Link>
                                <LogoutButton className="btn-default btn-sm btn-outline-success my-2 my-sm-0" />
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default AppHeader;
