import React, { Component } from 'react';
import PkceCheckbox from '../../../components/shared/pkce-checkbox';
import PasswordCheckbox from '../../../components/shared/password-checkbox';
import OverrideEmailValidation from '../../../components/shared/override-email-validation';
import DelegateSettings from '../../../components/shared/delegate-settings';

class OauthFeaturesCapsule extends Component {

    render() {

        return (
            <div className="row" style={{ marginTop: '1%' }}>
                <div className="col-12">
                    <span className="text-left">
                        <strong>OAuth Features</strong>
                    </span>
                    <div style={{ marginLeft: '0.5rem' }}>
                        <PkceCheckbox onChange={this.props.onPkceChanged}
                                      checked={this.props.pkceChecked} />
                    </div>
                    <div style={{ marginLeft: '0.5rem' }}>
                        <PasswordCheckbox onChange={this.props.onPasswordChanged}
                                          checked={this.props.passwordChecked} />
                    </div>
                    <div style={{ marginLeft: '0.5rem' }}>
                        <OverrideEmailValidation onChange={this.props.onOverrideEmailValidationChanged}
                                                 checked={this.props.overrideEmailValidationChecked} />
                    </div>
                    <div style={{ marginLeft: '0.5rem' }}>
                        <DelegateSettings
                          delegate={this.props.delegate}
                          onChange={this.props.onDelegateChanged}
                          onWhitelistChange={this.props.onDelegateWhitelistChanged}
                          onAdd={this.props.onDelegateWhitelistAdded}
                          onRemove={this.props.onDelegateWhitelistRemoved}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default OauthFeaturesCapsule;
