import React, { Component } from 'react';

class OrganizationSelectItem extends Component {

    constructor() {

        super();
        this.state = {
            selected: false
        };

        this.onSelect = this.onSelect.bind(this);
    }

    onSelect() {

        const active = !this.state.selected;

        this.setState({ selected: !this.state.selected });
        return this.props.onSelect(this.props.id, active);
    }

    render() {

        const listItemClasses = `list-group-item${this.state.selected ? ' active' : ''}`;

        return (
            <li className={listItemClasses} onClick={this.onSelect}>
                <div className="row" style={{ cursor: 'pointer' }}>
                    <div className="col-3">
                        <i className="fas fa-sitemap"></i>
                    </div>
                    <div className="col-9 text-right">
                        {this.props.name}
                    </div>

                </div>
            </li>
        );
    }
}

export default OrganizationSelectItem;
