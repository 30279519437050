import React, { Component } from 'react';
import SummaryHeader from '../../../components/shared/summary-header';
import {
    Link
} from 'react-router-dom';
import Modal from '../../../components/shared/modal'
import Api from '../../../components/shared/api';

class MemberSummary extends Component {

    constructor() {

        super();
        this.state = {
            showDeleteModal: false
        };

        this.api = new Api();
        this.onDeleteOpen = this.onDeleteOpen.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.onDeleteFinish = this.onDeleteFinish.bind(this);
    }

    onDeleteOpen() {

        this.setState({ showDeleteModal: true });
    }

    onDeleteFinish() {

        this.setState({ showDeleteModal: false });
    }

    onDeleteConfirm() {

        const payload = {
            members: [ this.props.id ]
        };

        this.api.exec(`/user/organizations/${this.props.organizationId}`, 'DELETE', payload)
        .then((confirmation) => {

            this.props.onChange(this.props.id);
            //this.onDeleteFinish();
        })
        .catch((err) => {

            console.error(err);
            alert(`There was a problem processing your request. Please try again later.`);
        });
    }

    render() {

        const deleteButton =
            this.props.deletable ?
            (
                <div className="col-4">
                    <Modal id={`#delete-${this.props.name}`}
                           label={`#delete-${this.props.name}-label`}
                           title={`Remove user ${this.props.name}?`}
                           body={`Are you sure you want to remove ${this.props.name} from this organization?`}
                           show={this.state.showDeleteModal}
                           onConfirm={this.onDeleteConfirm}
                           onDeny={this.onDeleteFinish} />

                    <button className="btn btn-sm btn-danger" onClick={this.onDeleteOpen}>
                        <i className="fas fa-times" style={{ cursor: 'pointer' }}></i>
                    </button>
                </div>
            ) : <div className="col-4">&nbsp;</div>;

        return (
            <li className="list-group-item list-group-item-action list-group-item-info">
                <div className="row">
                    <div className="col-9">
                        {this.props.name}
                    </div>
                    <div className="col-3 text-right">
                        <div className="row justify-content-between">
                            <div className="offset-4"></div>
                            <div className="col-4">
                                <Link to={`/users/${this.props.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <button className="btn btn-sm btn-default">
                                        <i className="fas fa-user"></i>
                                    </button>
                                </Link>
                            </div>
                            {deleteButton}
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

class MembersSummary extends Component {

    constructor() {

        super();

        this.onChange = this.onChange.bind(this);
    }

    render() {

        return (
            <ul style={{ marginTop: '2%' }} className="list-group">
                <SummaryHeader name="Members" create={`/organizations/${this.props.organizationId}/members/add`} image="fa-pencil-alt" />
                {this.props.members.length > 0 ? this.props.members.map((a) => {
                    return <MemberSummary
                                id={a._id}
                                key={a._id}
                                organizationId={this.props.organizationId}
                                name={a.username}
                                deletable={a._id !== this.props.creator._id}
                                onChange={this.onChange} />
                }) :
                <li className="list-group-item text-center disabled"><em>No members</em></li>}
            </ul>
        );
    }

    onChange(id) {

        const index = this.props.members.findIndex((m) => { return m._id === id; });
        if (index >= 0) {
            this.props.members.splice(index, 1);
            this.props.onChange(this.props.members);
        }
    }
}

export default MembersSummary;
