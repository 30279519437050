import React, { Component } from 'react';

class ProviderSelect extends Component {

    constructor() {

        super();
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    onSelectChange(e) {

        const value = (e.target.value === '' ? null : e.target.value);
        this.props.onProviderChange(value);
    }

    render() {

        return (
            <div className="form-group">
                <select className="form-control" id="provider-select" onChange={this.onSelectChange} defaultValue={this.props.selected || ""}>
                    <option key="none" value="">None</option>
                    {
                        this.props.providers.map((p) => {
                            return (
                                <option key={p.name} value={p.name}>
                                    {p.displayName}
                                </option>
                            );
                        })
                    }
                </select>
            </div>
        );
    }
}

export default ProviderSelect;
