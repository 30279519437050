import React, { Component } from 'react';
import {
    Redirect
} from 'react-router-dom';
import Api from '../../../components/shared/api';
import OrganizationSelect from './organization-select';
import FormError from '../../../components/shared/form-error';

class CreateForm extends Component {

    constructor() {

        super();
        this.state = {
            username: '',
            password: '',
            repeat: '',
            userOrgs: [],
            error: null,
            redirect: null
        };

        this.api = new Api();
        this.onSelect = this.onSelect.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.createTextUpdaterFor = this.createTextUpdaterFor.bind(this);
    }

    render() {

        const { redirect, error } = this.state;

        if (redirect) {
            return (<Redirect to={redirect} />);
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <FormError message={error} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="row">
                                <div className="col-12">
                                    <input type="text" className="form-control" placeholder="Username" onChange={this.createTextUpdaterFor('username')} />
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '10%' }}>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <input type="password" className="form-control" placeholder="Password" onChange={this.createTextUpdaterFor('password')} />
                                        </div>
                                        <div className="col-12">
                                            <input type="password" className="form-control" placeholder="Repeat Password" onChange={this.createTextUpdaterFor('repeat')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <OrganizationSelect onSelect={this.onSelect} />
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '2%' }}>
                        <div className="col-6 text-center">
                            <button className="btn btn-primary" onClick={this.onSubmit}>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    createTextUpdaterFor(name) {

        return function(e) {

            this.setState({
                [name]: e.target.value
            });
        }.bind(this);
    }

    onSubmit() {

        const {
            username,
            password,
            repeat,
            userOrgs
        } = this.state;

        if (username === null || username.length === 0) {
            return this.setState({ error: 'You must provide a username.' });
        }

        if (password === null || password.length === 0) {
            return this.setState({ error: 'You must provide a password.' });
        }

        if (password.length < 8) {
            return this.setState({ error: 'The password must be at least 8 characters in length.' });
        }

        if (repeat === null || repeat.length === 0) {
            return this.setState({ error: 'Please enter the password again.' });
        }

        if (password !== repeat) {
            return this.setState({ error: 'The passwords provided do not match. Please re-enter and try again.' });
        }

        const payload = {
            username,
            password,
            scope: [ 'administrator' ],
            organizations: userOrgs
        };

        this.api.exec('/users', 'POST', payload)
        .then((result) => {

            if (result.error) {
                return this.setState({ error: result.message });
            }

            return this.setState({ redirect: '/' });
        })
        .catch((err) => {

            this.setState({ error: err.toString() });
        });
    }

    onSelect(id, active) {

        let mustUpdate = false;
        const { userOrgs } = this.state;

        if (!active) {
            const index = userOrgs.findIndex((u) => { return u === id; });
            if (index >= 0) {
                userOrgs.splice(index, 1);
                mustUpdate = true;
            }
        }
        else {
            if (userOrgs.findIndex((u) => { return u === id; }) === -1) {
                userOrgs.push(id);
                mustUpdate = true;
            }
        }

        if (mustUpdate) {
            this.setState({ userOrgs });
        }
    }
}

export default CreateForm;
