import React from 'react';
import ImplicitCore from './implicit-core';

class ImplicitButton extends ImplicitCore {

    constructor(props) {

        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {

        super.onImplicitChange(!this.state.implicit);
    }

    render() {

        const implicitClasses = `btn btn-sm btn-${this.state.implicit ? 'success' : 'danger' }`;
        const implicitText = this.state.implicit ?
                               'Disable Implicit'
                             : 'Enable Implicit'
        const component = (
            <button className={implicitClasses} onClick={this.onClick}>{implicitText}</button>
        );

        return super.render(component);
    }
}

export default ImplicitButton;
