import React, { Component } from 'react';
import Api from '../../../components/shared/api';
import SummaryHeader from '../../../components/shared/summary-header';
import OrganizationSelectItem from './organization-select-item';

class OrganizationSelect extends Component {

    constructor() {

        super();
        this.state = {
            available: [],
            selected: []
        };
        this.onSelect = this.onSelect.bind(this);
        this.api = new Api();
    }

    componentWillMount() {

        this.api.exec('/organizations')
        .then((available) => {

            this.setState({ available });
        })
        .catch((err) => {

            console.error(err);
            alert(`A problem occurred. Please refresh to try again.`);
        });
    }

    onSelect(id, active) {

        return this.props.onSelect(id, active);
    }

    render() {

        return (
            <ul className="list-group">
                <SummaryHeader name="Select Organizations" />
                {this.state.available.length > 0 ?
                    this.state.available.map((a) => {

                        return (
                            <OrganizationSelectItem id={a._id} name={a.name} onSelect={this.onSelect} />
                        )
                    })
                    : <li className="list-group-item text-center"><em>No organizations available.</em></li>
                }
            </ul>
        );
    }
}

export default OrganizationSelect;
