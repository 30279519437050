import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
    LoginHero,
    LoginContainer
} from './components';

import '../../styles/golfid.css';

class Login extends Component {
    constructor() {

        super();
        this.state = {
            redirectToReferrer: false
        };
        this.onSuccess = this.onSuccess.bind(this);
        this.codeFromSearch = this.codeFromSearch.bind(this);
    }

    onSuccess() {

        this.setState({ redirectToReferrer: true });
    }

    codeFromSearch(status) {

        status = status.slice(1);
        const fieldEntries = status.split('&');
        const fieldMap = fieldEntries.reduce((obj, curr) => {

            const field = curr.split('=');
            if (field.length > 1) {
                obj[field[0]] = field[1];
            }

            return obj;
        }, {});

        return fieldMap.r || null;
    }

    render() {

        const code = this.codeFromSearch(this.props.location.search);
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        const { redirectToReferrer } = this.state;

        if (redirectToReferrer) {
            return (
                <Redirect to={from} />
            )
        }

        return (
            <div className="login-master-container vertical-center">
                <LoginHero />
                <LoginContainer code={code} onSuccess={this.onSuccess} />
            </div>
        );
    }
}

export default Login;
