import React, { Component } from 'react';
import {
    withRouter
} from 'react-router-dom';
import Api from './api';


class LogoutButtonBasics extends Component {

    constructor() {

        super();
        this.handleClick = this.handleClick.bind(this);
        this.api = new Api();
    }

    render() {

        const classes = `btn${this.props.className ? ` ${this.props.className}` : ''}`;

        return (
            <button className={classes} onClick={this.handleClick}>
                <i className="fas fa-power-off"></i>
            </button>
        );
    }

    handleClick() {

        const { history } = this.props;

        return this.api.exec('/auth', 'DELETE')
        .then((result) => {

            localStorage.removeItem('gnid.admin');
            history.push('/login');
        })
        .catch((err) => {

            console.error(err);
        });
    }
}

const LogoutButton = withRouter(LogoutButtonBasics);
export default LogoutButton;
