import React, { Component } from 'react';
import OrganizationList from '../../../components/shared/organization-list';
import UserList from './user-list';

class HomeApp extends Component {

    render() {

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h3 className="text-center">Welcome to GolfID</h3>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '3%' }}>
                    <div className="offset-2 col-4">
                        <OrganizationList href="/user/organizations" />
                    </div>
                    <div className="col-4">
                        <UserList />
                    </div>
                </div>
            </div>
        );
    }
}

export {
    HomeApp
};
