import React, { Component } from 'react';
import CreateForm from './create-form';
import Api from '../../../components/shared/api';

class MembersAddApp extends Component {

    constructor() {

        super();
        this.state = {
            organization: null
        };
        this.api = new Api();
    }

    componentDidMount() {

        this.api.exec(`/user/organizations/${this.props.organizationId}`)
        .then((organization) => {

            this.setState({ organization });
        })
        .catch((err) => {

            console.error(err);
            alert(`A problem occurred. Please refresh to try again.`);
        })
    }

    render() {

        if (!this.state.organization) {
            return null;
        }

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h3 className="text-center">Add Members to {this.state.organization.name}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <CreateForm organizationId={this.props.organizationId} />
                    </div>
                </div>
            </div>
        );
    }
}

export {
    MembersAddApp
};
