import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom';

class SummaryHeader extends Component {

    render() {

        const image = `fas ${this.props.image || 'fa-plus'}`;
        const createLink = this.props.create ?
                           (
                               <Link to={this.props.create} className="float-right" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
                                   <i className={image}></i>
                               </Link>
                           ) : null;

        return (
            <li className="text-center list-group-item nav-header disabled"
                style={{ fontWeight: 'bolder' }}>
                <a>
                    {this.props.name}
                </a>
                {createLink}
            </li>
        );
    }
}

export default SummaryHeader;
