import React, { Component } from 'react';
import Modal from '../../../components/shared/modal';

class DetailFooter extends Component {

    constructor() {

        super();
        this.state = {
            showModal: false
        };

        this.onDelete = this.onDelete.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.onDeleteDeny = this.onDeleteDeny.bind(this);
    }

    onDelete() {

        if (this.props.hasApplications) {
            return this.props.onDelete('This organization has active applications. Please delete all applications before deleting the organization.');
        }

        this.setState({ showModal: true });
    }

    onDeleteConfirm() {

        this.props.onDelete();
    }

    onDeleteDeny() {

        this.setState({ showModal: false });
    }

    render() {

        return (
            <div className="row" style={{ marginTop: '2%' }}>
                <div className="col-12 text-right">
                    <Modal
                        title="Delete Organization?"
                        body="Are you SURE you want to delete this organization? This is permanent and cannot be undone!"
                        show={this.state.showModal}
                        onConfirm={this.onDeleteConfirm}
                        onDeny={this.onDeleteDeny}
                    />
                    <button className="btn btn-danger" onClick={this.onDelete}>Delete Organization</button>
                </div>
            </div>
        );
    }
}

export default DetailFooter;
