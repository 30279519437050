import React, { Component } from 'react';

class RedirectItem extends Component {

    constructor() {

        super();
        this.onRemove = this.onRemove.bind(this);
    }

    onRemove() {

        return this.props.onRemove(this.props.url);
    }

    render() {

        return (
            <li className="list-group-item">
                <div className="row">
                    <div className="col-6">
                        {this.props.url}
                    </div>
                    <div className="col-6 text-right">
                        <span style={{ cursor: 'pointer' }} onClick={this.onRemove}>
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </li>
        );
    }
}

class RedirectsPanel extends Component {

    constructor() {

        super();
        this.state = {
            newDomain: ''
        };
        this.onNewDomainKeyed = this.onNewDomainKeyed.bind(this);
        this.onNewDomainPossiblySubmitted = this.onNewDomainPossiblySubmitted.bind(this);
    }

    onNewDomainKeyed(e) {

        this.setState({ newDomain: e.target.value });
    }

    onNewDomainPossiblySubmitted(e) {

        if (e.key === 'Enter') {
            this.props.onAdd(this.state.newDomain.slice());
            this.setState({ newDomain: '' });
        }
    }

    render() {

        const title = this.props.title ?
                      (<span className="text-center"><strong>{this.props.title}</strong></span>) :
                      null;

        return (
            <div className="row">
                <div className="col-12">
                    {title}
                    <div className="row">
                        <div className="col-12">
                            <ul className="list-group">
                                {
                                    this.props.urls.map((u) => {

                                        return <RedirectItem key={u} url={u} onRemove={this.props.onRemove}/>
                                    })
                                }
                                <li className="list-group-item">
                                    <style dangerouslySetInnerHTML={{__html: `
                                        .no-lines:focus {
                                            outline: none;
                                            border-color: inherit;
                                            -webkit-box-shadow: none;
                                            box-shadow: none;
                                        }
                                    `}}>
                                    </style>
                                    <input type="text"
                                           value={this.state.newDomain}
                                           className="form-control no-lines"
                                           style={{ border: 'none' }}
                                           placeholder={this.props.placeholder}
                                           onChange={this.onNewDomainKeyed}
                                           onKeyPress={this.onNewDomainPossiblySubmitted}
                                           />
                                </li>
                            </ul>
                        </div>
                        <div className="col-6">
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RedirectsPanel;
