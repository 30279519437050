import React, { Component } from 'react';
import ApplicationsSummary from './applications-summary';
import CredentialSummary from './credential-summary';
import MembersSummary from './members-summary';
import OrganizationSettings from './organization-settings';

class DetailBody extends Component {

    constructor() {

        super();

        this.onApplicationsSummaryAltered = this.onApplicationsSummaryAltered.bind(this);
        this.onMembersSummaryAltered = this.onMembersSummaryAltered.bind(this);
        this.onLinkedTrustClicked = this.onLinkedTrustClicked.bind(this);
    }

    onApplicationsSummaryAltered(applications) {

        this.props.onGridAltered('applications', applications);
    }

    onMembersSummaryAltered(members) {

        this.props.onGridAltered('members', members);
    }

    onLinkedTrustClicked() {

        this.props.onLinkedTrustClicked();
    }

    render() {

        return (
            <div className="row">
                <div className="col-6">
                    <div className="row">
                        <div className="col-12">
                            <ApplicationsSummary organizationId={this.props.organizationId} applications={this.props.applications} onChange={this.onApplicationsSummaryAltered} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <OrganizationSettings
                                linkedTrust={this.props.linkedTrust}
                                onLinkedTrustClicked={this.onLinkedTrustClicked} />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-12">
                            <CredentialSummary
                                credentials={this.props.credentials}
                                onChangeSecret={this.props.onChangeSecret}
                                onDeleteCredentials={this.props.onDeleteCredentials}
                                onGenerateCredentials={this.props.onGenerateCredentials}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <MembersSummary
                                organizationId={this.props.organizationId}
                                members={this.props.members}
                                creator={this.props.creator}
                                onChange={this.onMembersSummaryAltered} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailBody;
