import React, { Component } from 'react';
import CreateForm from './create-form';

class ApplicationCreateApp extends Component {

    render() {

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h3 className="text-center">Create an Application</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <CreateForm organizationId={this.props.organizationId} />
                    </div>
                </div>
            </div>
        );
    }
}

export {
    ApplicationCreateApp
};
