import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SummaryHeader from '../../../components/shared/summary-header';
import Api from '../../../components/shared/api';

class UserListItem extends Component {

    render() {

        const itemClasses = `list-group-item ${this.props.active ? 'list-group-item-success' : 'list-group-item-danger' }`;

        return (
            <li className={itemClasses}>
                <Link to={`/users/${this.props.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div className="row">
                        <div className="col-9">
                            {this.props.name}
                        </div>
                        <div className="col-3 text-right">
                            <div className="row">
                                <div className="col-12">
                                    <i className="fas fa-sitemap"></i>&nbsp;{this.props.organizations}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </li>
        );
    }
}

class UserList extends Component {

    constructor() {

        super();
        this.state = {
            users: []
        };
        this.api = new Api();
    }

    componentDidMount() {

        this.api.exec('/users?returnDeleted=true', 'GET')
        .then((users) => {

            this.setState({ users })
        })
        .catch((err) => {

            console.error(err);
            alert('A problem occurred. Please refresh to try again.');
        })
    }

    render() {

        return (
            <ul className="list-group">
                <SummaryHeader name="Users" create={`/users/create`} />
                {this.state.users.length > 0 ? this.state.users.map((o) => {

                    return (
                        <UserListItem id={o._id}
                                      key={o._id}
                                      name={o.username}
                                      active={o.status.active}
                                      organizations={o.organizations.length} />
                    )
                }) : <li className="list-group-item text-center"><em>No users...so how are you here?.</em></li>}
            </ul>
        );
    }
}

export default UserList;
