import React, { Component } from 'react';
import StatusCard from '../../../components/shared/status-card';

class AppStatusPanel extends Component {

    render() {

        const {
            application
        } = this.props;

        if (!application) {
            return null;
        }

        const statusCardData = [{
            title: 'Organization',
            data: application.organization,
            link: (o) => {

                return `/organizations/${o._id}`;
            },
            transform: (o) => {

                return (
                    <span><i className="fas fa-sitemap"></i>&nbsp;&nbsp;{o.name}</span>
                );
            }
        }, {
            title: 'Created By',
            data: application.createdBy,
            styles: { cursor: 'pointer' },
            link: (d) => {

                return `/users/${d._id}`;
            },
            transform: (d) => {

                return (
                    <span><i className="fas fa-user"></i>&nbsp;&nbsp;{d.username}</span>
                );
            }
        }, {
            title: 'Active',
            data: application.active ? 'Yes' : 'No',
            classes: application.active ? 'list-group-item-success' : 'list-group-item-danger'
        }, {
            title: 'Implicit',
            data: application.implicit ? 'Yes' : 'No',
            classes: application.implicit ? 'list-group-item-danger' : 'list-group-item-success'
        }, {
            title: 'Created',
            data: application.createdAt,
            type: 'date'
        }, {
            title: 'Last Updated',
            data: application.updatedAt,
            type: 'date'
        }]

        return (
            <StatusCard header="Status" data={statusCardData} />
        )
    }
}

export default AppStatusPanel;
